import { Box, Container, Grid, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import SendIcon from "@mui/icons-material/Send";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../../player/Mega2ball/index.css";
import React, { useEffect, useRef, useState } from "react";
import bg from "../../images/bg-1.jpg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import redball from "../../images/red_ball1.png";
import greenBall from "../../images/green-ball.png";
import RoundBall from "../../component/RoundBall";
import blueBall from "../../images/bule_ball1.png";
import blueButton from "../../images/Blue_button1.png";
import greenButton from "../../images/Green-button.png";
import NumberBox from "../../player/Mega3ball/NumberBox";
import redButton from "../../images/Red_button1.png";
import LineLeftRight from "../../component/LineLeftRight";
import lineLeft from "../../images/1st-line-left.png";
import lineRight from "../../images/1st-line-Right.png";
import clearImage from "../../images/clear.png";
import luckyPickImage from "../../images/lucky-pick1.png";
import totalPayoutBtn from "../../images/player mega-2/total.png";
import confirmImage from "../../images/Confirm.png";
import chatImg from "../../Asset/mobile/chat.png";
import straightBetButton from "../../images/player mega-3/Straight-bet-button.png";
import rambolitoBtn6 from "../../images/player mega-3/Rumbolito6-bet-button.png";
import rambolitoButton3 from "../../images/player mega-3/Rumbolito3-bet-button.png";
import ActionBtnMega3ball from "../../player/Mega3ball/ActionBtnMega3ball";
import BetHandle from "../../player/Mega3ball/BetHandle";
import underlineHistory from "../../images/player mega-3/underline-history.png";
// import audioMuteImage from "../../images/player mega-2/audio-mute.png";
// import userProfileImage from "../../images/profile.png";
// import audioImage from "../../images/player mega-2/audio.png";
// import CloseIcon from "@mui/icons-material/Close";
// import GameSwitchHandler from "../../component/GameSwitchHandler";
// import underlineSwitchGame from "../../images/InfoScreen/underline.png";
// import switchGameButtonNo from "../../images/SwitchGame/no-button.png";
// import switchGameButtonYes from "../../images/SwitchGame/yes-button.png";
import okayButtonInfoPayout from "../../images/InfoScreen/oky-button.png";
// import gameRulesUndarline from "../../images/InfoScreen/underline.png";
import Coin from "../../images/coin.png";
// import TotalAmount from "../../component/TotalAmount";
// import PopupButtonsHandler from "../../component/PopupButtonsHandler";
import clickOkImages from "../../images/SwitchGame/yes-button.png";

import CryptoJS from "crypto-js";

import creditPoint from "../../images/player mega-3/creadit.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Connection from "../../services/SignalRConnectionHub";

// import congratulationStartBg from "../../images/resultPopup/shadow-for-congrats-popup.png";
// import betterLuckBg from "../../images/resultPopup/Shadow-better-luck-next-tym.png";
// import betterLuckImg from "../../images/resultPopup/Better-luck-new.png";

// import congratulationStartImg from "../../images/resultPopup/congratulation-pop-up.png";
import {
  ChatApiMega3,
  PlaceBetMega3,
  ReloadMega3BallPlayerPage,
  ResultHistoryMega3Ball,
  SameDayHistoryMega3,
  WinnerCalculationMega3Ball,
  cancelRound,
  validateUserSessionID,
} from "../../APIHandler/API";
import {
  StartTimerMega3,
  ChatListMega,
  SettleRoundMega3,
} from "../../APIHandler/OperatorAPI";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import {
  confirmMSg,
  submitResultForMega3Ball,
  warningMsg,
} from "../../services/helpers";
import ValidateGame from "./ValidateGame";
import InputEmoji from "react-input-emoji";
// import VolumeOffIcon from "@mui/icons-material/VolumeOff";
// import { MoreVert } from "@mui/icons-material";
import SoftButtonClickAudio from "../../Asset/audio/SoftGameButtonClick.mp3";
import TribalNotificationAudio from "../../Asset/audio/TribalNotification.mp3";
import SoftNegativeToneAudio from "../../Asset/audio/SoftNegativeTone.mp3";
import PlayfulMusicalGameEndingAudio from "../../Asset/audio/GameEnding/PlayfulMusicalGameEnding.mp3";
import PopupAudio from "../../Asset/audio/Popup.mp3";
import CombinationAudio from "../../Asset/audio/Pop02.mp3";
import TimerAudio from "../../Asset/audio/GameTimer_5_v1.wav";
import useSound from "use-sound";
import BeepAudio from "../../Asset/audio/beep.mp3";
import RevealAudio from "../../Asset/audio/magic_bonus_reveal.mp3";
import TimerPopup from "./TimerPopup";
import EndSession from "./EndSession";
import { WalletBalance } from "../../component/WalletBalance";
import { LoserModal } from "../../component/UI/Modal/LoserModal";
import RulesModal from "../../component/UI/Modal/RulesModal";
import WinnerModal from "../../component/UI/Modal/WinnerModal";
import SwitchGame from "../../component/UI/Modal/SwitchGame";
import NavbarMega3 from "../../component/UI/Navbar/NavbarMega3";
import PausedModal from "../../component/UI/Modal/PausedModal";
import HistoryModal from "../../component/UI/Modal/HistoryModal";
import GameHistoryMega3 from "../../component/SameDayGameHistory/GameHistoryMega3";
import PlayerGameHistoryIndex from "../../component/PlayerGameHistory/PlayerGameHistoryIndex";
import { InactiveUser } from "../../component/UI/Modal/InactiveUser";
// import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import BackdropRotate, {
  isMobile,
} from "../../component/BackdropRotate/BackdropRotate";
import "../Mega2ball/index.css";
import { useMemo } from "react";
// import Rambolito6LuckyPick from "./Rambolito6LuckyPick";
import generateThreeNonMatchingRandomNumbers from "./Rambolito6LuckyPick.jsx";
import landscapeSwipeUP from "../../Asset/mobile/landscape-Swipe-UP.gif";
// import PlayerLiveStream from "../Mega2ball/webRTC/PlayerLiveStream";

import TCPlayer from "tcplayer.js";

function Index() {
  const [straightBetData, setStraightBetData] = useState([]);
  const [rambolito3BetData, setRambolito3BetData] = useState([]);
  const [rambolito6BetData, setRambolito6BetData] = useState([]);
  const [activeBet, setActiveBet] = useState("StraightBet");
  const [betDisable, setBetDisable] = useState("StraightBet");

  const [straightBetCount, setStraightBetCount] = useState(0);
  const [rambolito3Count, setRambolito3Count] = useState(0);
  const [rambolito6Count, setRambolito6Count] = useState(0);
  const [historyData3, setHistoryData3] = useState();
  const [redNumber, setRedNumber] = useState(-1);
  const [greenNumber, setGreenNumber] = useState(-1);
  const [blueNumber, setBlueNumber] = useState(-1);
  const [currentInput, setCurrentInput] = useState(0);
  const [rumbalito3CurentInput, setRumbalito3CurentInput] = useState(0);
  const [rumbalito6CurentInput, setRumbalito6CurentInput] = useState(0);
  const [sameDayhistoryData, setSameDayHistoryData] = useState([]);

  const [showChatbox, setShowChatbox] = useState(false);
  const [hidingHistory, setHidingHistory] = useState(true);

  const [gameInfoRule, setGameInfoRule] = useState(false);
  const [SwitchModalOpen, setSwitchModalOpen] = useState(false);
  const [showBox, setShowBox] = useState("gameRuleBox");
  const [showMute, setShowMute] = useState(
    JSON.parse(localStorage.getItem("showMute"))
  );
  const [gameHistoryOpen, setGameHistoryOpen] = useState("");
  const [endGameSession, setEndGameSession] = useState(false);

  const [clickRedBtn, setClickRedBtn] = useState(false);
  const [clickGreenBtn, setClickGreenBtn] = useState(false);

  const [gameSessionId, setGameSessionId] = useState(0);
  const [minutes, setminutes] = useState("00");
  const [seconds, setseconds] = useState("00");

  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState("");

  const [params] = useSearchParams();

  const [isRunning, setIsRunning] = useState(false);
  const [timerStopPopup, setTimerStopPopup] = useState(false);
  const [resumedTimerPopup, setResumedTimerPopup] = useState(false);

  const [submitType, setSubmitType] = useState("AUTO");
  const [resultPopup, setResultPopup] = useState();
  const [disableStraightCount, setDisableStraightCount] = useState(false);
  const [disableRamblito3Count, setDisableRamblito3Count] = useState(false);
  const [disableRamblito6Count, setDisableRamblito6Count] = useState(false);
  const [disableFirstRamblito3, setDisableFirstRamblito3] = useState(-1);
  const [disableSecondRamblito3, setDisableSecondRamblito3] = useState(-1);
  const [historyType, setHistoryType] = useState(true);
  const [playerId, setPlayerId] = useState();
  const [playerName, setPlayerName] = useState();
  const [balance, setBalance] = useState(100);
  const [playerStatus, setPlayerStatus] = useState(false);
  const winningAmount = useRef("-");
  const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
  const [playTribalNotificationAudio] = useSound(TribalNotificationAudio);
  const [playSoftNegativeToneAudio] = useSound(SoftNegativeToneAudio);
  const [playPopupAudio] = useSound(PopupAudio);
  const [playPlayfulMusicalGameEndingAudio] = useSound(
    PlayfulMusicalGameEndingAudio
  );
  const [playCombinationAudio] = useSound(CombinationAudio);
  const [playTimerAudio] = useSound(TimerAudio);
  const [playBeepAudio] = useSound(BeepAudio);
  let x = JSON.parse(localStorage.getItem("showMute"));
  const currentPlayerId = useRef(0);

  const totalBetAmount = useMemo(() => {
    let countStraight =
      straightBetData.filter((e) => e.length == 3)?.length * straightBetCount;
    let countRumble3 =
      rambolito3BetData.filter((e) => e.length == 3)?.length * rambolito3Count;
    let countRumble6 =
      rambolito6BetData.filter((e) => e.length == 3)?.length * rambolito6Count;
    // console.log("G",straightBetData,straightBetCount,rambolito3BetData,rambolito3Count,rambolito6BetData,rambolito6Count)
    return countStraight + countRumble3 + countRumble6;
  }, [
    straightBetData,
    rambolito3BetData,
    rambolito6BetData,
    straightBetCount,
    rambolito3Count,
    rambolito6Count,
    straightBetData.filter((e) => e.length == 3)?.length,
    rambolito3BetData.filter((e) => e.length == 3)?.length,
    rambolito6BetData.filter((e) => e.length == 3)?.length,
  ]);
  const [showSwipeUp, setShowSwipeUp] = useState("hideSwipeUp");

  const content = useRef(null);
  const enterFullScreen = () => {
    console.log("enterfullscreen==",content.current);
    if (content.current) {
      if (content.current.requestFullscreen) {
        content.current.requestFullscreen();
      } else if (content.current.mozRequestFullScreen) {
        // Firefox
        content.current.mozRequestFullScreen();
      } else if (content.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        content.current.webkitRequestFullscreen();
      } else if (content.current.msRequestFullscreen) {
        // IE/Edge
        content.current.msRequestFullscreen();
      }
    }
  };
  useEffect(() => {
    if (isMobile.any()) {
      setShowSwipeUp("showSwipeUp");
      console.log("Popup for swipe up");
    }
    const swipeUpBox = document.getElementById("swipeUpBox");

    const handleTouchStart = (event) => {
      event.preventDefault(); // Prevent default behavior if necessary
      enterFullScreen();
      setShowSwipeUp("hideSwipeUp"); // Call your function
      console.log("Touch event occurred");
    };

    if (swipeUpBox) {
      swipeUpBox.addEventListener("touchend", handleTouchStart);
      swipeUpBox.addEventListener("touchmove", handleTouchStart);
    } else {
      console.error("Element with ID 'swipeUpBox' not found.");
    }
  }, []);

  if (x !== true && x !== false) {
    localStorage.setItem("showMute", false);
    setShowMute(false);
  }
  function enableChat() {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    document
      .querySelector(".chatBox-playersPageMega3")
      .classList.toggle("test");
    document.querySelector(".liveStreamingImage").classList.toggle("test");
    document.querySelector(".historyComponent3").classList.toggle("test");
  }
  //Collapsible Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    document
      .querySelector(".chatBox-playersPageMega3")
      .classList.remove("test");
    document.querySelector(".liveStreamingImage").classList.remove("test");
    document.querySelector(".historyComponent3").classList.remove("test");
    switch (value) {
      case "history":
        handleHistoryIconBox();
        break;
      case "Switch":
        handleSwitchGame();
        break;
      case "Mute":
        clickAudioImage();
        break;
      case "Info":
        handleGameInfo();
        break;
    }
    setAnchorEl(null);
  };
  let audio = useRef();
  useEffect(() => {
    if (betDisable === "StraightBet") {
      if (straightBetData[currentInput]?.length < 3) {
        setstraightBetClickStatus(true);
      }
      if (
        straightBetData[currentInput]?.length == 3 ||
        straightBetData[currentInput]?.length == 0
      ) {
        setstraightBetClickStatus(false);
      } else if (straightBetData?.length == 9) {
        setstraightBetClickStatus(false);
      }
    } else if (betDisable === "rambolitoPayout3") {
      if (rambolito3BetData[rumbalito3CurentInput]?.length < 3) {
        setstraightBetClickStatus(true);
      }
      if (
        rambolito3BetData[rumbalito3CurentInput]?.length == 3 ||
        rambolito3BetData[rumbalito3CurentInput]?.length == 0
      ) {
        setstraightBetClickStatus(false);
      } else if (rambolito3BetData?.length == 9) {
        setstraightBetClickStatus(false);
      }
    } else {
      if (rambolito6BetData[rumbalito6CurentInput]?.length < 3) {
        setstraightBetClickStatus(true);
      }
      if (
        rambolito6BetData[rumbalito6CurentInput]?.length == 3 ||
        rambolito6BetData[rumbalito6CurentInput]?.length == 0
      ) {
        setstraightBetClickStatus(false);
      } else if (rambolito6BetData?.length == 9) {
        setstraightBetClickStatus(false);
      }
    }
  }, [
    straightBetData,
    straightBetData[currentInput]?.length,
    rambolito3BetData,
    rambolito3BetData[rumbalito3CurentInput]?.length,
    rambolito6BetData,
    rambolito6BetData[rumbalito6CurentInput]?.length,
  ]);
  useEffect(() => {
    straightBetData.length > 0 && straightBetCount > 0
      ? setDisableStraightCount(true)
      : setDisableStraightCount(false);
  }, [straightBetData.length]);
  useEffect(() => {
    rambolito3BetData.length > 0 && rambolito3Count > 0
      ? setDisableRamblito3Count(true)
      : setDisableRamblito3Count(false);
  }, [rambolito3BetData.length]);
  useEffect(() => {
    rambolito6BetData.length > 0 && rambolito6Count > 0
      ? setDisableRamblito6Count(true)
      : setDisableRamblito6Count(false);
  }, [rambolito6BetData.length]);
  const [straightBetClickStatus, setstraightBetClickStatus] = useState(true);
  const selectCurrentBox = (curentInput, index, data, setCurentInput) => {
    if (straightBetClickStatus) {
      return 1;
    }
    setCurentInput((prev) => index);
    if (data && data[index]?.length === 1) {
      setClickRedBtn(true);
      setClickGreenBtn(false);
    } else if (data && data[index]?.length === 2) {
      setClickRedBtn(true);
      setClickGreenBtn(true);
    } else {
      setClickRedBtn(false);
      setClickGreenBtn(false);
    }
  };
  const checkResult = async (_, sessionID) => {
    const payload = {
      playerId: playerId ?? currentPlayerId.current,
      gameSessionId: sessionID,
      token: params.get("token"),
    };
    const value = {
      sessionId: sessionID,
      token: params.get("token"),
    };
    console.log("VALUEE", payload, value);
    try {
      const res1 = await ReloadMega3BallPlayerPage(value);
      let strtLength = res1?.data?.straightBet?.bets?.length;
      let strtCount =
        Number(res1?.data?.straightBet?.betAmount) /
          Number(res1?.data?.straightBet?.numberOfBets || 1) || 0;
      let ram3Length = res1?.data?.rambolitoThreeBet?.bets?.length;
      let ram3Count =
        Number(res1?.data?.rambolitoThreeBet?.betAmount) /
          Number(res1?.data?.rambolitoThreeBet?.numberOfBets || 1) || 0;
      let ram6Length = res1?.data?.rambolitoSixBet?.bets?.length;
      let ram6Count =
        Number(res1?.data?.rambolitoSixBet?.betAmount) /
          Number(res1?.data?.rambolitoSixBet?.numberOfBets || 1) || 0;
      const response = await WinnerCalculationMega3Ball(payload);
      if (
        (strtCount > 0 && strtLength > 0) ||
        (ram3Count > 0 && ram3Length > 0) ||
        (ram6Count > 0 && ram6Length > 0)
      ) {
        console.log(
          "res",
          response,
          response?.straight,
          response?.rambolitoThree
        );
        if (
          response?.straight != 0 ||
          response?.rambolitoThree != 0 ||
          response?.rambolitoSix != 0
        ) {
          winningAmount.current = response?.amount;
          console.log(
            "win ===",
            response,
            response?.amount,
            winningAmount,
            winningAmount.current
          );
          submitResultForMega3Ball("congratulations");
          if (showMute === false) {
            audio.current = playTribalNotificationAudio;
            new Audio(TribalNotificationAudio).play();
          }
          setResultPopup(true);
          setTimeout(() => {
            setResultPopup();
            winningAmount.current = "-";
          }, 3000);
        } else {
          setResultPopup(false);
          if (showMute === false) {
            audio.current = playSoftNegativeToneAudio;
            new Audio(SoftNegativeToneAudio).play();
          }
          setTimeout(() => {
            setResultPopup();
            winningAmount.current = "-";
          }, 3000);
        }
      } else {
        if (showMute === false) {
          audio.current = RevealAudio;
          new Audio(RevealAudio).play();
        }
      }
    } catch (error) {
      warningMsg("Something went wrong");
    }
  };
  const clickOkPaused = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setTimerStopPopup(false);
    setIsRunning(false); // Hide the popup when OK button is clicked
  };
  let TimerStarted;
  const GameIsPlaying = useRef(false);
  const GAME_S_ID = useRef();
  function ShowPopup(message, data) {
    clearInterval(TimerStarted);
    TimerStarted = setTimeout(async () => {
      if (message.timerState === "Play") {
        if (
          redNumber == -1 &&
          blueNumber == -1 &&
          greenNumber == -1 &&
          totalBetAmount > 0
        ) {
          let data = {
            token: params.get("token"),
            playerGameRoundId: localStorage.getItem("playerGameRoundId"),
            gameId: 2,
          };

          console.log("Calling cancel round", minutes, seconds);
          cancelRoundMega3(data);
        }
        //Check for new game
        if (GAME_S_ID.current != data.gameSessionId) {
          const res1 = await StartTimerMega3();
          setGameSessionId(res1?.gameSessionId);
          GAME_S_ID.current = res1?.gameSessionId;
          setBetDisable("StraightBet");
          historyApiCall();
          sameDayHistory();
          initialState();
          let x = JSON.parse(localStorage.getItem("Submit"));
          if (res1?.result?.message === "Game Session Ended") {
            if (showMute === false) {
              playPlayfulMusicalGameEndingAudio();
            }
            setEndGameSession(true);
            setBetDisable("Confirm_Bet");
          }
          if (
            res1?.result?.message === "Game Session Is Not Ended" &&
            x !== true
          ) {
            setBetDisable("StraightBet");
          }
          if (x == true) {
            setBetDisable("Confirm_Bet");
          }

          //Write here to fetch wallet balance of user
          validateUserSession();
        }

        setTimerStopPopup(false);
        if (GameIsPlaying.current) {
          setResumedTimerPopup(true);
          GameIsPlaying.current = false;
        }
        setTimeout(() => {
          setResumedTimerPopup(false);
        }, 2000);
      } else if (message.timerState === "Pause") {
        if (showMute === false) {
          playBeepAudio();
        }
        setTimerStopPopup(true);
        GameIsPlaying.current = true;
        setResumedTimerPopup(false);
        // setTimeout(() => {
        //   setTimerStopPopup(false);
        // }, 2000);
      }
    }, 1000);
  }

  const cancelRoundMega3 = async (data) => {
    //Call cancel round
    const res2 = await cancelRound(data);
    if (res2) {
      console.log("cancel round mega3 ", res2);
    }
  };
  //Connect to the signalR
  useEffect(() => {
    Connection.on("TimerStateMegaThree", (message, data) => {
      ShowPopup(message, data);
    });
    Connection.on("timerUpdateMegaThree", (message) => {
      setminutes(`${message}`?.split(":")[0]);
      setseconds(`${message}`?.split(":")[1]);
      setIsRunning(true);
      if (message === "01:00") {
        warningMsg("Bet is closed");
        setBetDisable("StraightBet");
      }
      if (message === "01:30") {
        warningMsg("Hurry up! Bet will close soon");
        if (showMute === false) {
          playTimerAudio();
        }
      }
    });
    Connection.on("SessionEndedMegaThree", (messages) => {
      setEndGameSession(true);
      let s = JSON.parse(localStorage.getItem("showMute"));
      if (s === false) {
        playPlayfulMusicalGameEndingAudio();
      }
      setBetDisable("Confirm_Bet");
      setTimeout(() => setEndGameSession(false), 2000);
    });
    Connection.on("ResultMegaThreeBall", (response) => {
      setRedNumber(response?.results[0] > -1 ? response?.results[0] : -1);
      setGreenNumber(response?.results[1] > -1 ? response?.results[1] : -1);
      setBlueNumber(response?.results[2] > -1 ? response?.results[2] : -1);
      checkResult(true, response?.gameSessionId);
    });
    Connection.off();
    Connection.on("SendMessageMegaThree", (chatMessage) => {
      setChats((prev) => [...prev, chatMessage]);
    });
  }, []);
  useEffect(() => {
    if (minutes === "00" && seconds === "00") {
      if (submitType === "AUTO") {
        autoConfirm();
      }
    }
    if (minutes === "00" && seconds === "01") {
      console.log("first");
      if (
        redNumber == -1 &&
        blueNumber == -1 &&
        greenNumber == -1 &&
        totalBetAmount > 0
      ) {
        let data = {
          token: params.get("token"),
          playerGameRoundId: localStorage.getItem("playerGameRoundId"),
          gameId: 2,
        };

        console.log("Calling cancel round", minutes, seconds);
        cancelRoundMega3(data);
      }
    }
  }, [minutes, seconds]);
  //Validate Members Identification
  const validateUserSession = async () => {
    let payload = params.get("token");
    let payload1 = { token: payload };
    const result = await validateUserSessionID(payload1);
    if (result) {
      setPlayerStatus(result?.data?.data?.data?.status != "ACTIVE");
      currentPlayerId.current = result?.data?.data?.data?.id;
      setPlayerId(result?.data?.data?.data?.id);
      setPlayerName(result?.data?.data?.data?.name);
      setBalance(result?.data?.data?.data?.balance);
    } else {
      warningMsg("Error occured while getting player's detail");
    }
  };
  //Timer start For Mega 2 Ball in player Page
  useEffect(() => {
    const starTiming = async () => {
      const res = await StartTimerMega3();
      setminutes(res?.timer ? `${res?.timer}`?.split(":")[0] : "00");
      setseconds(res?.timer ? `${res?.timer}`?.split(":")[1] : "00");
      setGameSessionId(res?.gameSessionId);
      let s = JSON.parse(localStorage.getItem("showMute"));
      if (res?.result?.message === "Game Session Ended") {
        if (s === false) {
          setTimeout(() => {
            playPlayfulMusicalGameEndingAudio();
          }, 3000);
        }
        setEndGameSession(true);
        setBetDisable("Confirm_Bet");
        localStorage.setItem("submit", false);
      } else if (res?.result?.message === "Game Session Is Not Ended") {
        setBetDisable("StraightBet");
      }
      if (
        res?.result?.results?.[0] >= 0 &&
        res?.result?.results?.[1] >= 0 &&
        res?.result?.results?.[2] >= 0
      ) {
        setRedNumber(res?.result?.results?.[0]);
        setGreenNumber(res?.result?.results?.[1]);
        setBlueNumber(res?.result?.results?.[2]);
        checkResult(true, gameSessionId);
      }
    };
    starTiming();
    validateUserSession();
  }, []);

  const initialState = () => {
    setClickRedBtn(false);
    setClickGreenBtn(false);
    setEndGameSession(false);
    setBetDisable("StraightBet");
    setStraightBetData([]);
    setRambolito3BetData([]);
    setRambolito6BetData([]);
    setStraightBetCount(0);
    setRambolito3Count(0);
    setRambolito6Count(0);
    setRedNumber(-1);
    setGreenNumber(-1);
    setBlueNumber(-1);
    setCurrentInput(0);
    setRumbalito3CurentInput(0);
    setRumbalito6CurentInput(0);
    setSubmitType("AUTO");
    setResultPopup();
    winningAmount.current = "-";
    localStorage.setItem("submit", false);
  };
  //Restart The Timer When (minutes === "00" && seconds === "00")
  useEffect(() => {
    const settleRoundBalance = async () => {
      const payload = {
        gameSessionId: gameSessionId,
        token: params.get("token"),
      };
      const res1 = await SettleRoundMega3(payload);
      console.log("settleRound Balance", res1);
    };
    if (minutes === "00" && seconds === "01") {
      if (
        redNumber > -1 &&
        blueNumber > -1 &&
        greenNumber > -1 &&
        totalBetAmount > 0
      ) {
        settleRoundBalance();
      }
    }
    const RestartTimer = async () => {
      if (minutes === "00" && seconds === "00") {
        const res1 = await StartTimerMega3();
        setGameSessionId(res1?.gameSessionId);
        GAME_S_ID.current = res1?.gameSessionId;
        setBetDisable("StraightBet");
        historyApiCall();
        sameDayHistory();
        initialState();
        let x = JSON.parse(localStorage.getItem("Submit"));
        if (res1?.result?.message === "Game Session Ended") {
          if (showMute === false) {
            playPlayfulMusicalGameEndingAudio();
          }
          setEndGameSession(true);
          setBetDisable("Confirm_Bet");
        }
        if (
          res1?.result?.message === "Game Session Is Not Ended" &&
          x !== true
        ) {
          setBetDisable("StraightBet");
        }
        if (x == true) {
          setBetDisable("Confirm_Bet");
        }

        //Write here to fetch wallet balance of user
        validateUserSession();
      }
    };
    RestartTimer();

    //Disable if 1 min is left
    if ((minutes === "01" && seconds === "00") || minutes === "00") {
      setBetDisable("Confirm_Bet");
    }
  }, [minutes, seconds]);

  const ClickSendMsg = async () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    let x = messages.replace(/\s+/g, " ").trim();
    if (x !== "" && x !== " ") {
      const value = {
        playerName: playerName,
        playerId: playerId,
        message: messages,
        dateTime: new Date(),
      };
      try {
        const response = await ChatApiMega3(value);
        // console.log({response})
      } catch (error) {}
      setMessages("");
    }
  };
  const historyApiCall = async () => {
    try {
      const result = await ResultHistoryMega3Ball();
      if (result) {
        setHistoryData3(result?.history);
      } else {
        warningMsg("Error occur while getting history");
      }
    } catch (e) {
      warningMsg("Error occured while getting history");
    }
  };
  useEffect(() => {
    historyApiCall();
    sameDayHistory();
    chatList();
  }, []);
  //SameDay Game History APIs
  const sameDayHistory = async () => {
    const response = await SameDayHistoryMega3();
    console.log({response});
    setSameDayHistoryData(response);
  };
  //Click Red button for Straight-Bet, Rumbalito-Bet3, Rambalito-Bet6
  const handleRedButton = (value) => {
    let amount =
      currentInput * straightBetCount +
      rumbalito3CurentInput * rambolito3Count +
      rumbalito6CurentInput * rambolito6Count;
    if (amount >= balance) {
      console.warn("Balance Red exceeds");
      warningMsg("Bet amount exceeds current balance");
      return;
    }
    if (showMute === false) {
      playCombinationAudio();
    }
    if (betDisable === "StraightBet") {
      if (straightBetData[currentInput]?.length === 3) {
        warningMsg("Current box is already filled!");
        setstraightBetClickStatus(false);
      } else if (
        straightBetData[currentInput]?.length == 0 &&
        currentInput < 9
      ) {
        const updatedData = [...straightBetData];
        updatedData[currentInput] = [value];
        setStraightBetData(updatedData);
        setstraightBetClickStatus(true);
      } else if (straightBetData?.length == 0 || currentInput < 9) {
        const updatedData = [...straightBetData];
        updatedData[currentInput] = [value];
        setStraightBetData(updatedData);
        setstraightBetClickStatus(true);
      }
      setClickRedBtn(true);
      setClickGreenBtn(false);
      return 1;
    } else if (betDisable === "rambolitoPayout3") {
      if (rambolito3BetData[rumbalito3CurentInput]?.length === 3) {
        warningMsg("Current box is already filled!");
      } else if (
        rambolito3BetData[rumbalito3CurentInput]?.length == 0 &&
        rumbalito3CurentInput < 9
      ) {
        const updatedData = [...rambolito3BetData];
        updatedData[rumbalito3CurentInput] = [value];
        setRambolito3BetData(updatedData);
      } else if (rambolito3BetData?.length == 0 || rumbalito3CurentInput < 9) {
        const updatedData = [...rambolito3BetData];
        updatedData[rumbalito3CurentInput] = [value];
        setRambolito3BetData(updatedData);
      }
      setDisableFirstRamblito3(value);
      setClickRedBtn(true);
      setClickGreenBtn(false);
      return 1;
    } else {
      if (rambolito6BetData[rumbalito6CurentInput]?.length === 3) {
        warningMsg("Current box is already filled!");
      } else if (
        rambolito6BetData[rumbalito6CurentInput]?.length == 0 &&
        rumbalito6CurentInput < 9
      ) {
        const updatedData = [...rambolito6BetData];
        updatedData[rumbalito6CurentInput] = [value];
        setRambolito6BetData(updatedData);
      } else if (rambolito6BetData?.length == 0 || rumbalito6CurentInput < 9) {
        const updatedData = [...rambolito6BetData];
        updatedData[rumbalito6CurentInput] = [value];
        setRambolito6BetData(updatedData);
      }
      setClickRedBtn(true);
      setClickGreenBtn(false);
      return 1;
    }
  };
  //Click Green button for Straight-Bet, Rumbalito-Bet3, Rambalito-Bet6
  const handleGreenButton = (value) => {
    let amount =
      currentInput * straightBetCount +
      rumbalito3CurentInput * rambolito3Count +
      rumbalito6CurentInput * rambolito6Count;
    if (amount >= balance) {
      warningMsg("Bet amount exceeds current balance");
      return;
    }
    if (showMute === false) {
      playCombinationAudio();
    }
    if (betDisable === "StraightBet") {
      if (clickRedBtn && !clickGreenBtn) {
        const updatedData = [...straightBetData];
        updatedData[currentInput]?.push(value);
        setStraightBetData(updatedData);
        setClickGreenBtn(true);
      }
    } else if (betDisable === "rambolitoPayout3") {
      if (clickRedBtn && !clickGreenBtn) {
        const updatedData = [...rambolito3BetData];
        updatedData[rumbalito3CurentInput]?.push(value);
        setRambolito3BetData(updatedData);
        setDisableSecondRamblito3(value);
        setClickGreenBtn(true);
      }
    } else {
      if (clickRedBtn && !clickGreenBtn) {
        const updatedData = [...rambolito6BetData];
        updatedData[rumbalito6CurentInput]?.push(value);
        setRambolito6BetData(updatedData);
        setClickGreenBtn(true);
      }
    }
  };

  //Click Blue button for Straight-Bet, Rumbalito-Bet3, Rambalito-Bet6
  const handleBlueButton = (value) => {
    if (showMute === false) {
      playCombinationAudio();
    }
    if (betDisable === "StraightBet") {
      if (clickRedBtn && clickGreenBtn) {
        const updatedData = [...straightBetData];
        updatedData[currentInput]?.push(value);
        setCurrentInput(currentInput + 1);
        setStraightBetData(updatedData);
        setClickRedBtn(false);
        setClickGreenBtn(false);
      }
    } else if (betDisable === "rambolitoPayout3") {
      if (clickRedBtn && clickGreenBtn) {
        const updatedData = [...rambolito3BetData];
        updatedData[rumbalito3CurentInput]?.push(value);
        setRumbalito3CurentInput(rumbalito3CurentInput + 1);
        setRambolito3BetData(updatedData);
        setClickRedBtn(false);
        setClickGreenBtn(false);
      }
    } else {
      if (clickRedBtn && clickGreenBtn) {
        const updatedData = [...rambolito6BetData];
        updatedData[rumbalito6CurentInput]?.push(value);
        setRumbalito6CurentInput(rumbalito6CurentInput + 1);
        setRambolito6BetData(updatedData);
        setClickRedBtn(false);
        setClickGreenBtn(false);
      }
    }
  };
  const handleStraightBet = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setDisableFirstRamblito3(-1);
    setDisableSecondRamblito3(-1);
    setActiveBet("StraightBet");
    setBetDisable("StraightBet");
    if (straightBetData[straightBetData?.length - 1]?.length === 2) {
      setClickGreenBtn(true);
      setClickRedBtn(true);
    } else if (straightBetData[straightBetData?.length - 1]?.length === 1) {
      setClickGreenBtn(false);
      setClickRedBtn(true);
    } else {
      setClickGreenBtn(false);
      setClickRedBtn(false);
    }
  };
  const handleRambolito3 = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    if (rambolito3BetData[rambolito3BetData?.length - 1]?.length === 2) {
      setClickGreenBtn(true);
      setClickRedBtn(true);
    } else if (rambolito3BetData[rambolito3BetData?.length - 1]?.length === 1) {
      setClickGreenBtn(false);
      setClickRedBtn(true);
    } else {
      setClickGreenBtn(false);
      setClickRedBtn(false);
    }
    setActiveBet("rambolitoPayout3");
    setBetDisable("rambolitoPayout3");
  };
  const handleRambolito6 = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setDisableFirstRamblito3(-1);
    setDisableSecondRamblito3(-1);
    if (rambolito6BetData[rambolito6BetData?.length - 1]?.length === 2) {
      setClickGreenBtn(true);
      setClickRedBtn(true);
    } else if (rambolito6BetData[rambolito6BetData?.length - 1]?.length === 1) {
      setClickGreenBtn(false);
      setClickRedBtn(true);
    } else {
      setClickGreenBtn(false);
      setClickRedBtn(false);
    }
    setActiveBet("playerRambolitoBet6");
    setBetDisable("playerRambolitoBet6");
  };
  function EncryptText(plainText, key) {
    const keyBytes = CryptoJS.enc.Base64.parse(key);
    const plainBytes = CryptoJS.enc.Utf8.parse(plainText);
    const encryptedBytes = CryptoJS.AES.encrypt(plainBytes, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedText = encryptedBytes.toString();
    return encryptedText;
  }
  const generateEncryptKey = (mode) => {
    //0 means button  and 1 means AUTo submit
    var ciphertext =
      mode === "BUTTON"
        ? EncryptText("0", process.env.REACT_APP_SECRET_KEY).toString()
        : EncryptText("1", process.env.REACT_APP_SECRET_KEY).toString();
    return ciphertext;
  };
  //AUTO Bet placed
  const autoConfirm = () => {
    // Splice data if set is not complete else confirm the Bet
    if (straightBetData[straightBetData?.length - 1]?.length < 3) {
      straightBetData?.splice(straightBetData?.length - 1, 1);
      if (activeBet === "StraightBet") {
        setClickGreenBtn(false);
        setClickRedBtn(false);
      }
    }
    if (rambolito3BetData[rambolito3BetData?.length - 1]?.length < 3) {
      rambolito3BetData?.splice(rambolito3BetData?.length - 1, 1);
      if (activeBet === "rambolitoPayout3") {
        setClickGreenBtn(false);
        setClickRedBtn(false);
      }
    }
    if (rambolito6BetData[rambolito6BetData?.length - 1]?.length < 3) {
      rambolito6BetData?.splice(rambolito6BetData?.length - 1, 1);
      if (activeBet === "playerRambolitoBet6") {
        setClickGreenBtn(false);
        setClickRedBtn(false);
      }
    }
    if (straightBetCount == 0) {
      setStraightBetData([]);
    }
    if (rambolito3Count == 0) {
      setRambolito3BetData([]);
    }
    if (rambolito6Count == 0) {
      setRambolito6BetData([]);
    }
    if (straightBetData.length == 0) {
      setStraightBetCount(0);
    }
    if (rambolito3BetData.length == 0) {
      setRambolito3Count(0);
    }
    if (rambolito6BetData.length == 0) {
      setRambolito6Count(0);
    }
    if (
      (straightBetData.length > 0 && straightBetCount > 0) ||
      (rambolito3BetData.length > 0 && rambolito3Count) ||
      (rambolito6BetData.length > 0 && rambolito6Count)
    ) {
      handleConfirm("AUTO");
    }
  };
  //placeBet for mega-3 ball, when users select the numbers
  const handleConfirm = async (clickedBy) => {
    let submitMode = generateEncryptKey(clickedBy);
    let countStraight =
      straightBetData.filter((e) => e.length == 3)?.length * straightBetCount;
    let countRumble3 =
      rambolito3BetData.filter((e) => e.length == 3)?.length * rambolito3Count;
    let countRumble6 =
      rambolito6BetData.filter((e) => e.length == 3)?.length * rambolito6Count;
    const value = {
      gameSessionId: gameSessionId,
      playerId: playerId,
      submitMode: submitMode,
      straightBet: {
        betAmount: countStraight,
        numberOfBets: straightBetData.filter((e) => e.length == 3)?.length,
        bets: straightBetData.filter((e) => e.length == 3),
      },
      rambolitoThreeBet: {
        betAmount: countRumble3,
        numberOfBets: rambolito3BetData.filter((e) => e.length == 3)?.length,
        bets: rambolito3BetData.filter((e) => e.length == 3),
      },
      rambolitoSixBet: {
        betAmount: countRumble6,
        numberOfBets: rambolito6BetData.filter((e) => e.length == 3)?.length,
        bets: rambolito6BetData.filter((e) => e.length == 3),
      },
      token: params.get("token"),
    };
    try {
      const res = await PlaceBetMega3(value);
      if (res) {
        clickedBy === "AUTO"
          ? setTimeout(() => confirmMSg("AUTO BET PLACED"), 4000)
          : confirmMSg("Your Bet is placed");
        clickedBy === "BUTTON"
          ? setSubmitType("BUTTON")
          : setSubmitType("AUTO");
        localStorage.setItem("submit", true);
        localStorage.setItem("playerGameRoundId", res?.data?.data?.data?.id);
        console.log(
          "Balance left ::",
          res?.data,
          res?.data?.data?.data?.id,
          res?.data?.data?.included?.member?.balance
        );
        setBalance(res?.data?.data?.included?.member?.balance);
        setBetDisable("Confirm_Bet");
      } else {
        localStorage.setItem("submit", false);
        warningMsg("Error occur while placing bet");
        setBetDisable("StraightBet");
      }
    } catch (e) {
      localStorage.setItem("submit", false);
      warningMsg("Error occur while placing bet");
      setBetDisable("StraightBet");
    }
  };
  const handleButtonConfirm = async () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    if (
      !ValidateGame(
        straightBetData,
        straightBetCount,
        rambolito3BetData,
        rambolito3Count,
        rambolito6BetData,
        rambolito6Count
      )
    ) {
      //No Warning Showed then confirm the bet
      handleConfirm("BUTTON");
    }
  };
  const amountShow = () => {
    // console.log("confirm");
  };
  const clearData = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    if (betDisable === "StraightBet") {
      if (straightBetData?.length) {
        setStraightBetData((prevData) => [
          ...new Set([...prevData, currentInput]),
        ]);
        if (straightBetData.length == currentInput) {
          let x = straightBetData.length - 1;
          let y = straightBetData;
          setStraightBetData(y.slice(0, x));
          setCurrentInput((prev) => prev - 2);
          return;
        }
        if (currentInput > 0) {
          setCurrentInput((prev) => prev - 1);
        }
        if (straightBetData[currentInput]?.length != 0) {
          setStraightBetData(
            straightBetData.map((data, i) => (i === currentInput ? [] : data))
          );
        }
      }
    } else if (betDisable === "rambolitoPayout3") {
      if (rambolito3BetData?.length) {
        setRambolito3BetData((prevData) => [
          ...new Set([...prevData, rumbalito3CurentInput]),
        ]);
        if (rambolito3BetData.length == rumbalito3CurentInput) {
          let x = rambolito3BetData.length - 1;
          let y = rambolito3BetData;
          setRambolito3BetData(y.slice(0, x));
          setRumbalito3CurentInput((prev) => prev - 2);
          return;
        }
        if (rumbalito3CurentInput > 0) {
          setRumbalito3CurentInput((prev) => prev - 1);
        }
        if (rambolito3BetData[rumbalito3CurentInput]?.length != 0) {
          setRambolito3BetData(
            rambolito3BetData.map((data, i) =>
              i === rumbalito3CurentInput ? [] : data
            )
          );
        }
      }
    } else {
      if (rambolito6BetData?.length) {
        setRambolito6BetData((prevData) => prevData.slice(0, -1));
        if (rambolito6BetData.length == rumbalito6CurentInput) {
          let x = rambolito6BetData.length - 1;
          let y = rambolito6BetData;
          setRambolito6BetData(y.slice(0, x));
          setRumbalito6CurentInput((prev) => prev - 2);
          return;
        }
        if (rumbalito6CurentInput > 0) {
          setRumbalito6CurentInput((prev) => prev - 1);
        }
        if (rambolito6BetData[rumbalito6CurentInput]?.length != 0) {
          setRambolito6BetData(
            rambolito6BetData.map((data, i) =>
              i === rumbalito6CurentInput ? [] : data
            )
          );
        }
      }
    }
    setClickRedBtn(false);
    setClickGreenBtn(false);
  };

  const handleluckypick = () => {
    let amount = 0;
    if (activeBet == "StraightBet") {
      amount =
        (currentInput + 1) * straightBetCount +
        rumbalito3CurentInput * rambolito3Count +
        rumbalito6CurentInput * rambolito6Count;
    } else if (activeBet == "rambolitoPayout3") {
      amount =
        currentInput * straightBetCount +
        (rumbalito3CurentInput + 1) * rambolito3Count +
        rumbalito6CurentInput * rambolito6Count;
    } else {
      amount =
        currentInput * straightBetCount +
        rumbalito3CurentInput * rambolito3Count +
        (rumbalito6CurentInput + 1) * rambolito6Count;
    }
    if (amount > balance) {
      warningMsg("Bet amount will exceed current balance");
      return;
    }
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    const randomRedIndex = Math.floor(Math.random() * 9) + 0;
    const randomGreenIndex = Math.floor(Math.random() * 9) + 0;
    const randomBlueIndex = Math.floor(Math.random() * 9) + 0;
    if (betDisable === "StraightBet") {
      if (straightBetData[currentInput]?.length === 3) {
        warningMsg("Current box is already filled!");
      } else if (straightBetData[currentInput]?.length === 2) {
        straightBetData[currentInput].push(randomBlueIndex);
        setClickRedBtn(false);
        setClickGreenBtn(false);
      } else if (straightBetData[currentInput]?.length === 1) {
        straightBetData[currentInput].push(randomGreenIndex);
        straightBetData[currentInput].push(randomBlueIndex);
        setClickGreenBtn(true);
        setClickRedBtn(false);
      } else if (straightBetData[currentInput]?.length === 0) {
        straightBetData[currentInput].push(randomRedIndex);
        straightBetData[currentInput].push(randomGreenIndex);
        straightBetData[currentInput].push(randomBlueIndex);
        setClickGreenBtn(true);
        setClickRedBtn(false);
      } else {
        setStraightBetData((prevData) => [
          ...prevData,
          [randomRedIndex, randomGreenIndex, randomBlueIndex],
        ]);
      }
      setCurrentInput((prev) => (prev === 8 ? 8 : prev + 1));
    } else if (betDisable === "rambolitoPayout3") {
      if (rambolito3BetData[rumbalito3CurentInput]?.length === 3) {
        warningMsg("Current box is already filled!");
      } else if (rambolito3BetData[rumbalito3CurentInput]?.length === 2) {
        let firstFilledValue = rambolito3BetData[rumbalito3CurentInput][0];
        let secondFilledValue = rambolito3BetData[rumbalito3CurentInput][1];
        if (firstFilledValue === secondFilledValue) {
          let randomNum = Math.floor(Math.random() * 9) + 0;
          rambolito3BetData[rumbalito3CurentInput]?.push(
            randomNum === firstFilledValue
              ? ++randomNum === 10
                ? --randomNum
                : ++randomNum
              : randomNum
          );
        } else {
          const randomPosition = Math.floor(Math.random() * 2) + 1;
          switch (randomPosition) {
            case 1:
              rambolito3BetData[rumbalito3CurentInput]?.push(firstFilledValue);
              break;
            case 2:
              rambolito3BetData[rumbalito3CurentInput]?.push(secondFilledValue);
              break;
          }
        }
        setClickRedBtn(false);
        setClickGreenBtn(false);
      } else if (rambolito3BetData[rumbalito3CurentInput]?.length === 1) {
        //one filled
        let firstFilledValue = rambolito3BetData[rumbalito3CurentInput][0];
        let randomSecondNo = Math.floor(Math.random() * 9) + 0;
        randomSecondNo =
          firstFilledValue === randomSecondNo
            ? randomSecondNo - 1 === -1
              ? randomSecondNo + 3
              : ++randomSecondNo
            : randomSecondNo;
        const randomPosition = Math.floor(Math.random() * 3) + 1;
        switch (randomPosition) {
          case 1:
            rambolito3BetData[rumbalito3CurentInput]?.push(firstFilledValue);
            rambolito3BetData[rumbalito3CurentInput]?.push(randomSecondNo);
            break;
          case 2:
            rambolito3BetData[rumbalito3CurentInput]?.push(randomSecondNo);
            rambolito3BetData[rumbalito3CurentInput]?.push(randomSecondNo);
            break;
          case 3:
            rambolito3BetData[rumbalito3CurentInput]?.push(randomSecondNo);
            rambolito3BetData[rumbalito3CurentInput]?.push(firstFilledValue);
            break;
          default:
            break;
        }
        setClickGreenBtn(true);
        setClickRedBtn(false);
      } else if (rambolito3BetData[rumbalito3CurentInput]?.length === 0) {
        //  zero fill
        const randomFirstNo = Math.floor(Math.random() * 9) + 0;
        let randomSecondNo = Math.floor(Math.random() * 9) + 0;
        randomSecondNo =
          randomFirstNo === randomSecondNo
            ? randomSecondNo - 1 === -1
              ? randomSecondNo + 3
              : ++randomSecondNo
            : randomSecondNo;
        const randomPosition = Math.floor(Math.random() * 3) + 1;
        switch (randomPosition) {
          case 1:
            rambolito3BetData.splice(rumbalito3CurentInput, 1, [
              randomFirstNo,
              randomFirstNo,
              randomSecondNo,
            ]);
            break;
          case 2:
            rambolito3BetData.splice(rumbalito3CurentInput, 1, [
              randomFirstNo,
              randomSecondNo,
              randomSecondNo,
            ]);
            break;
          case 3:
            rambolito3BetData.splice(rumbalito3CurentInput, 1, [
              randomSecondNo,
              randomFirstNo,
              randomSecondNo,
            ]);
            break;
          default:
            break;
        }
      } else {
        //  zero fill
        const randomFirstNo = Math.floor(Math.random() * 9) + 0;
        let randomSecondNo = Math.floor(Math.random() * 9) + 0;

        randomSecondNo =
          randomFirstNo === randomSecondNo
            ? randomSecondNo - 1 === -1
              ? randomSecondNo + 3
              : ++randomSecondNo
            : randomSecondNo;
        const randomPosition = Math.floor(Math.random() * 3) + 1;
        switch (randomPosition) {
          case 1:
            setRambolito3BetData((prevData) => [
              ...prevData,
              [randomFirstNo, randomFirstNo, randomSecondNo],
            ]);
            break;
          case 2:
            setRambolito3BetData((prevData) => [
              ...prevData,
              [randomFirstNo, randomSecondNo, randomSecondNo],
            ]);
            break;
          case 3:
            setRambolito3BetData((prevData) => [
              ...prevData,
              [randomSecondNo, randomFirstNo, randomSecondNo],
            ]);
            break;
          default:
            break;
        }
      }
      setRumbalito3CurentInput((prev) => (prev === 8 ? 8 : prev + 1));
    } else {
      if (rambolito6BetData[rumbalito6CurentInput]?.length === 3) {
        warningMsg("Current box is already filled!");
      } else if (rambolito6BetData[rumbalito6CurentInput]?.length === 2) {
        rambolito6BetData[rumbalito6CurentInput] =
          generateThreeNonMatchingRandomNumbers(
            ...rambolito6BetData[rumbalito6CurentInput]
          );
        setClickRedBtn(false);
        setClickGreenBtn(false);
      } else if (rambolito6BetData[rumbalito6CurentInput]?.length === 1) {
        rambolito6BetData[rumbalito6CurentInput] =
          generateThreeNonMatchingRandomNumbers(
            ...rambolito6BetData[rumbalito6CurentInput]
          );
        setClickGreenBtn(true);
        setClickRedBtn(false);
      } else if (rambolito6BetData[rumbalito6CurentInput]?.length === 0) {
        rambolito6BetData[rumbalito6CurentInput] =
          generateThreeNonMatchingRandomNumbers();
        setClickGreenBtn(true);
        setClickRedBtn(false);
      } else {
        setRambolito6BetData((prevData) => [
          ...prevData,
          generateThreeNonMatchingRandomNumbers(),
        ]);
      }
      setRumbalito6CurentInput((prev) => (prev === 8 ? 8 : prev + 1));
    }
  };
  const handleSwitchGame = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setSwitchModalOpen(true);
  };
  let navigate = useNavigate();
  const clickYesToSwitch = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    navigate(`/player/Mega2Spin?token=${params.get("token")}`);
  };
  const clickNoToSwitch = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setSwitchModalOpen(false);
  };
  //Game Info & Rule handler
  const handleGameInfo = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setGameInfoRule(true);
  };
  const clickOkayInfoScreen = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setGameInfoRule(false);
  };
  //click Game Rules for Mega-2 Ball
  const clickGameRules = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowBox("gameRuleBox");
  };
  //click to see Payout
  const clickPayoutInfo = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowBox("payoutBox");
  };
  //Click too mute audio
  const clickAudioImage = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowMute(!showMute);
    localStorage.setItem("showMute", !showMute);
  };
  const handleHistoryIconBox = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setGameHistoryOpen(true);
  };
  const handleArrowDropUpChatBox = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowChatbox(!showChatbox);
  };
  const toggleHistoryShow = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setHidingHistory(!hidingHistory);
  };
  const chatList = async () => {
    const response = await ChatListMega({ gameId: 2 });
    setChats(response?.data);
  };
  //Reload API
  useEffect(() => {
    let submitStatus = JSON.parse(localStorage.getItem("submit"));
    if (submitStatus == true) {
      setBetDisable("Confirm_Bet");
    } else {
      setBetDisable("StraightBet");
    }
    if (gameSessionId !== 0) {
      const ReloadFunction = async () => {
        if (gameSessionId !== 0) {
          const value = {
            sessionId: gameSessionId,
            token: params.get("token"),
          };
          try {
            // validateUserSession();
            const response = await ReloadMega3BallPlayerPage(value);
           
            //Set Values here
            if (response?.data) {
              setBetDisable("Confirm_Bet");
              setStraightBetCount(
                Number(response?.data?.straightBet?.betAmount) /
                  Number(response?.data?.straightBet?.numberOfBets || 1) || 0
              );
              setStraightBetData(
                response?.data?.straightBet?.bets &&
                  response?.data?.straightBet?.bets !== null
                  ? response?.data?.straightBet?.bets
                  : []
              );
              setRambolito3Count(
                Number(response?.data?.rambolitoThreeBet?.betAmount) /
                  Number(
                    response?.data?.rambolitoThreeBet?.numberOfBets || 1
                  ) || 0
              );
              setRambolito3BetData(
                response?.data?.rambolitoThreeBet?.bets &&
                  response?.data?.rambolitoThreeBet?.bets !== null
                  ? response?.data?.rambolitoThreeBet?.bets
                  : []
              );
              setRambolito6Count(
                Number(response?.data?.rambolitoSixBet?.betAmount) /
                  Number(response?.data?.rambolitoSixBet?.numberOfBets || 1) ||
                  0
              );
              setRambolito6BetData(
                response?.data?.rambolitoSixBet?.bets &&
                  response?.data?.rambolitoSixBet?.bets !== null
                  ? response?.data?.rambolitoSixBet?.bets
                  : []
              );
              //Calculate amount and then set total amount
              let total =
                (response?.data?.straightBet?.betAmount || 0) +
                (response?.data?.rambolitoThreeBet?.betAmount || 0) +
                (response?.data?.rambolitoSixBet?.betAmount || 0);
              
              setCurrentInput(
                response ? Number(response?.data?.straightBet?.bets?.length) : 0
              );
              setRumbalito3CurentInput(
                response
                  ? Number(response?.data?.rambolitoThreeBet?.bets?.length)
                  : 0
              );
              setRambolito6Count(
                response
                  ? Number(response?.data?.rambolitoSixBet?.bets?.length)
                  : 0
              );
            }
            chatList();
          } catch (error) {
            console.log(error, "hello reload API error");
          }
        }
      };
      ReloadFunction();
    }
  }, [gameSessionId]);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [chats]);
  const handleCount = (val) => {
    let value = Number(val);
    let amount = 0;
    if (activeBet == "StraightBet") {
      amount =
        value * currentInput +
        rumbalito3CurentInput * rambolito3Count +
        rumbalito6CurentInput * rambolito6Count;
      if (amount > balance) {
        warningMsg("Bet amount exceeds current balance");
        return;
      } else setStraightBetCount(value);
    } else if (activeBet == "rambolitoPayout3") {
      amount =
        currentInput * straightBetCount +
        value * rumbalito3CurentInput +
        rumbalito6CurentInput * rambolito6Count;
      if (amount > balance) {
        warningMsg("Bet amount exceeds current balance");
        return;
      } else setRambolito3Count(value);
    } else {
      amount =
        currentInput * straightBetCount +
        rumbalito3CurentInput * rambolito3Count +
        value * rumbalito6CurentInput;
      if (amount > balance) {
        warningMsg("Bet amount exceeds current balance");
        return;
      } else setRambolito6Count(value);
    }
  };

  const player = useRef();

  useEffect(() => {
    player.current = TCPlayer("liveVideoPlayerMega3Balls", {
      sources: [
        {
          src: process.env.REACT_APP_MEGA2_LIVE_URL,
          // src: 'webrtc://global-lebtest-play.myqcloud.com/live/lebtest?txSecret=f22a813b284137ed10d3259a7b5c224b&txTime=69f1eb8c&tabr_bitrates=d1080p,d540p,d360p&tabr_start_bitrate=d1080p',
        },
      ],
      licenseUrl: process.env.REACT_APP_TCPLAYER_LICENSE_KEY, // License URL
      language: "en", // Setting language en | zh-CN
    });

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);

  return (
    <Box
      sm={12}
      md={12}
      xs={12}
      marginX={"85"}
      style={{
        backgroundImage: `url(${bg})`,
        minHeight: "100vh",
        marginTop: 0,
        backgroundSize: "cover",
        overflowY: "auto",
      }}
      ref={content}
    >
      {/* For Swipe up in landscape mode for mega3 ball */}
      {showSwipeUp && (
        <div id="swipeUpBox" className={`displaySwipeUp ${showSwipeUp}`}>
          <img
            width={"100%"}
            height={"100%"}
            className=" landscapeSwipeUP "
            src={landscapeSwipeUP}
          />
        </div>
      )}
      <BackdropRotate variant={"portrait"} />
      <InactiveUser playerStatus={playerStatus} />
      <ToastContainer />
      <Container maxWidth={false} className="custom-container">
        <Grid container style={{ height: "100vh" }}>
          {/* UserProfile, Switch-Box , User-History  */}
          <NavbarMega3
            navbar={"navbarMega3"}
            handleClick={handleClick}
            handleHistoryIconBox={handleHistoryIconBox}
            handleGameInfo={handleGameInfo}
            handleSwitchGame={handleSwitchGame}
            clickAudioImage={clickAudioImage}
            balance={balance}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            showMute={showMute}
            playerName={playerName}
          />
          {/* Alert message popup, When operator is end the gameSession */}
          <EndSession
            endGameSession={endGameSession}
            clickOkImages={clickOkImages}
            setEndGameSession={setEndGameSession}
          />
          {/* Alert message popup, When operator is stop timer */}
          <PausedModal
            timerStopPopup={timerStopPopup}
            msg={"The game has been paused !"}
            // clickOkPaused={clickOkPaused}
          />
          <TimerPopup
            resumedTimerPopup={resumedTimerPopup}
            clickOkImages={clickOkImages}
            clickOkPaused={clickOkPaused}
          />
          {/* /Game HistoryPopup-Model for Mega 3 ball / */}
          <HistoryModal
            gameHistoryOpen={gameHistoryOpen}
            betFrom={3}
            showMute={showMute}
            historyType={historyType}
            setGameHistoryOpen={setGameHistoryOpen}
            setHistoryType={setHistoryType}
            todayGameHistory={sameDayhistoryData}
            GameHistoryComponent={GameHistoryMega3}
            PlayerHistoryComponent={PlayerGameHistoryIndex}
          />

          {/* / Modal- Popup for switch Game in mega 2 ball/ */}
          <SwitchGame
            SwitchModalOpen={SwitchModalOpen}
            setSwitchModalOpen={setSwitchModalOpen}
            showMute={showMute}
            switchTo="2"
            clickNoToSwitch={clickNoToSwitch}
            clickYesToSwitch={clickYesToSwitch}
          />
          <WinnerModal
            congratsShowHide={resultPopup === true}
            // totalBetAmount={previousTotalAmount.current}
            totalBetAmount={winningAmount.current}
          />
          <LoserModal resultPopup={resultPopup === false} />
          {/* Game information -Like rules and Payout for player mega-3 ball */}
          <RulesModal
            gameInfo={gameInfoRule}
            setGameInfo={setGameInfoRule}
            clickGameRules={clickGameRules}
            clickPayoutInfo={clickPayoutInfo}
            showBox={showBox}
            okayButtonInfoPayout={okayButtonInfoPayout}
            clickOkayInfoScreen={clickOkayInfoScreen}
          />
          <Grid
            container
            item
            sm={12}
            md={12}
            xs={12}
            lg={12}
            columnSpacing={4}
            bgcolor={"#00000040"}
            sx={{ marginTop: { xs: "2px", lg: "10px" } }}
            marginBottom={"0px"}
            boxShadow={" gold"}
            className="containerPlayerMega3"
            paddingBottom="10px"
            height={"92vh"}
          >
            <Grid
              item
              lg={3.8}
              sx={{ width: { xs: "100%" } }}
              className={`${
                !hidingHistory ? "hide-history" : " "
              } videoOrientation`}
            >
              <Box
                sx={{
                  "&.liveStreamingImage.test": {
                    display: { xs: "none", lg: showChatbox ? "none" : "block" },
                  },
                  display: { lg: showChatbox ? "none" : "block" },
                }}
                className="liveStreamingImage liveVideo"
                style={{ height: showChatbox ? "0" : "320px" }}
              >
                <video
                  className={"liveVideo"}
                  id="liveVideoPlayerMega3Balls"
                  controls
                  autoPlay="true"
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                  }}
                  onLoadedData={() => {
                    player.current.play();
                  }}
                  preload="auto"
                  playsinline="true"
                  webkit-playsinline="true"
                ></video>
              </Box>
              {/* Game History of player page  */}
              <Grid
                className="historyComponent3"
                sx={{
                  "&.historyComponent3.test": {
                    display: {
                      xs: "none !important",
                      lg: showChatbox ? "none" : "block",
                    },
                  },
                  display: { lg: showChatbox ? "none" : "block" },
                }}
              >
                <Box className="arrowBox3" onClick={() => toggleHistoryShow()}>
                  <KeyboardArrowUpIcon className="arrowOption3" />
                </Box>
                <Box>
                  <p className="textHistory3"> HISTORY</p>
                </Box>
                <Box className={`history-box ${hidingHistory ? "show" : ""}`}>
                  <Box className="underlineHistory3">
                    <img src={underlineHistory} alt="historyBar" />
                  </Box>
                  <Box className="counting3">
                    <p className="countingMega3">1st</p>
                    <p className="countingMega3">2nd</p>
                    <p className="countingMega3">3rd</p>
                    <p className="countingMega3">4th</p>
                    <p className="countingMega3">5th</p>
                    <p className="countingMega3">6th</p>
                    <p className="countingMega3">7th</p>
                  </Box>
                  <Box className="gameHistory3">
                    {[...new Array(7)].map((a, i) => (
                      <Box key={i}>
                        <Box className="historyBox3">
                          {historyData3?.[i]?.item1}
                        </Box>
                        <Box className="historyBox23">
                          {historyData3?.[i]?.item2}
                        </Box>
                        <Box className="historyBox13">
                          {historyData3?.[i]?.item3}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Box
                sx={{
                  "&.chatBox-playersPageMega3": {
                    display: { xs: "none", lg: "block" },
                    marginTop: { xs: "0px", lg: "10px" },
                  },
                  "&.chatBox-playersPageMega3.test": {
                    display: "block",
                  },
                }}
                className="chatBox-playersPageMega3"
                style={{
                  fontFamily: "Poppins",
                  height: showChatbox ? "750px" : "",
                }}
              >
                <button className="livePlayer3">Live</button>
                <Box
                  className="arrowDropUpChatBox"
                  onClick={() => {
                    handleArrowDropUpChatBox();
                  }}
                  sx={{ display: { xs: "none", lg: "block" } }}
                >
                  <ArrowDropDownIcon />
                </Box>
                <Box className="chatboxMsgPlayerMega3">
                  {chats &&
                    chats.length > 0 &&
                    chats.map((chat, i) => (
                      <Box key={i} className="username1">
                        <Box className="emojiMega3">
                          <p className="emojiTag">
                            {chat?.playerName?.split("")[0]}
                          </p>
                          <p className="email">{chat?.playerName}</p>
                          <p className="sentnow">
                            {moment(chat?.dateTime).fromNow()}
                          </p>
                        </Box>
                        <p className="chatting3">{chat?.message}</p>
                      </Box>
                    ))}
                  <Box ref={messagesEndRef} />
                </Box>
                <Grid display={"flex"} gap={3}>
                  <Box className="typingBox3">
                    <InputEmoji
                      placeholder="Type your message......."
                      className="enter-Box"
                      value={messages}
                      cleanOnEnter
                      onChange={setMessages}
                      onEnter={ClickSendMsg}
                      sx={{
                        zIndex: {
                          xs:
                            endGameSession ||
                            timerStopPopup ||
                            gameHistoryOpen ||
                            SwitchModalOpen ||
                            resultPopup ||
                            gameInfoRule
                              ? "0"
                              : "1",
                        },
                      }}
                    />
                    <p
                      className="sendIcon3"
                      style={{
                        zIndex:
                          endGameSession ||
                          timerStopPopup ||
                          gameHistoryOpen ||
                          SwitchModalOpen ||
                          resultPopup ||
                          gameInfoRule
                            ? "0"
                            : "1",
                      }}
                      onClick={() => {
                        ClickSendMsg();
                      }}
                    >
                      <SendIcon />
                    </p>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              lg={5}
              className="playerMega3ball"
              style={{ width: "100%" }}
            >
              {/* Message Button here */}
              <Grid
                container
                justifyContent={"center"}
                sx={{ position: "relative" }}
              >
                <IconButton
                  onClick={enableChat}
                  aria-label="delete"
                  size="small"
                  sx={{
                    display: { xs: "block", lg: "none" },
                    position: "absolute",
                    right: "0",
                  }}
                >
                  <img
                    className="chatImgOrientation"
                    src={chatImg}
                    alt="Chat Icon"
                  />
                </IconButton>
                <Box className="timeBoxPlayerPageMega3">
                  <Box className="timmingShowBoxPlayerMega2">
                    <Box className="timerrShowBox"> {minutes || ""} </Box>
                    <p className="dotTimerr">:</p>
                    <Box className="timerrShowBox"> {seconds || ""} </Box>
                  </Box>
                  {isRunning ? "" : <Box className="paused">Paused</Box>}
                  <Box className="textTimerPlayerMega2">
                    <p>MINUTES</p>
                    <p>SECONDS</p>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  className="RoundBall-player-mega3"
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap="10px"
                >
                  <RoundBall
                    Images={redball}
                    className={"ball-2"}
                    data1={redNumber == -1 ? "" : redNumber}
                    roundBallClass="roundball3"
                  />
                  <RoundBall
                    Images={greenBall}
                    className={"ball-2"}
                    data1={greenNumber == -1 ? "" : greenNumber}
                    roundBallClass="roundball3"
                  />
                  <RoundBall
                    Images={blueBall}
                    className={"ball-2"}
                    data1={blueNumber == -1 ? "" : blueNumber}
                    roundBallClass="roundball3"
                  />
                </Box>
              </Grid>
              <Grid
                className="red-Green-BlueBox"
                style={{
                  opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                  pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                }}
              >
                <Box
                  position={"relative"}
                  style={{ opacity: !clickRedBtn ? "" : "0.7" }}
                >
                  <Box className="firstSecond">
                    <h5 className="text">
                      <LineLeftRight
                        Images={lineLeft}
                        className={"lineLeftPlayer"}
                      />
                      1st
                      <LineLeftRight
                        Images={lineRight}
                        className={"lineRightPlayer"}
                      />
                    </h5>
                  </Box>
                  <NumberBox
                    Images={redButton}
                    max={10}
                    className={"player-red-button"}
                    fontName={"Discoteca Rounded"}
                    startIndex={0}
                    selectNumber={handleRedButton}
                    fontSize={"130%"}
                  />
                </Box>
                <Box
                  style={{
                    opacity: clickRedBtn && !clickGreenBtn ? "" : "0.7",
                  }}
                >
                  <Box className="firstSecond">
                    <h5 className="text">
                      <LineLeftRight
                        Images={lineLeft}
                        className={"lineLeftPlayer"}
                      />
                      2nd
                      <LineLeftRight
                        Images={lineRight}
                        className={"lineRightPlayer"}
                      />
                    </h5>
                  </Box>
                  <NumberBox
                    Images={greenButton}
                    max={10}
                    className={"player-green-button"}
                    fontName={"Discoteca Rounded"}
                    startIndex={0}
                    selectNumber={handleGreenButton}
                    fontSize={"130%"}
                  />
                </Box>
                <Box
                  style={{ opacity: clickRedBtn && clickGreenBtn ? "" : "0.5" }}
                >
                  <Box className="firstSecond">
                    <h5 className="text">
                      <LineLeftRight
                        Images={lineLeft}
                        className={"lineLeftPlayer"}
                      />
                      3rd
                      <LineLeftRight
                        Images={lineRight}
                        className={"lineRightPlayer"}
                      />
                    </h5>
                  </Box>
                  <NumberBox
                    Images={blueButton}
                    max={10}
                    className={"player-blue-button"}
                    fontName={"Discoteca Rounded"}
                    startIndex={0}
                    selectNumber={handleBlueButton}
                    fontSize={"130%"}
                    disableFirstRamblito3={disableFirstRamblito3}
                    disableSecondRamblito3={disableSecondRamblito3}
                  />
                </Box>
              </Grid>
              <Grid
                className="confirmationButtonMega3 actionButtons"
                sx={{ display: { xs: "none", lg: "flex" } }}
              >
                <Box className="actionBox1">
                  <ActionBtnMega3ball
                    Images={clearImage}
                    text="CLEAR"
                    className={"actionButton"}
                    handleActionButton={clearData}
                    number={straightBetData}
                    style={{
                      opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                      pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                    }}
                  />
                  <Box className="totalPaymentbtnBox">
                    <ActionBtnMega3ball
                      Images={totalPayoutBtn}
                      text="total "
                      text2=" ₱ "
                      text3={totalBetAmount}
                      className={"totalPayoutBtn"}
                      style={{ width: "100%" }}
                      handleActionButton={amountShow}
                    />
                  </Box>
                </Box>
                <Box
                  className="actionBox2"
                  style={{
                    opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                    pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                  }}
                >
                  <ActionBtnMega3ball
                    Images={luckyPickImage}
                    text="LUCKY PICK"
                    className={"luckyPickImage"}
                    handleActionButton={() => handleluckypick()}
                    number={
                      betDisable === "StraightBet"
                        ? straightBetData
                        : betDisable === "rambolitoPayout3"
                        ? rambolito3BetData
                        : rambolito6BetData
                    }
                  />
                  <Box className="confirmbtnBox">
                    <ActionBtnMega3ball
                      Images={confirmImage}
                      text="CONFIRM"
                      className={"actionButton"}
                      handleActionButton={handleButtonConfirm}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item lg={3} xs={12} className="orientationNumberBox">
              <Grid className="orientationStraightBox">
                <Grid
                  className="creditBox landscapeCreditBox"
                  sx={{ display: { xs: "none", lg: "block" } }}
                >
                  <WalletBalance
                    Images={[creditPoint]}
                    image2={Coin}
                    text4={balance}
                    textCoin={"textCoin textCoin3"}
                    image2css={"image3"}
                    typo={"Name currencyContainer"}
                    text3={"₱"}
                    ActionButtonMega2Ball="ActionButtonMega2Ball"
                    className={"creditMega2Ball"}
                  />
                </Grid>
                <Grid
                  display={"flex"}
                  marginTop={"4px"}
                  gap={"20px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  className="orientationCreditBox"
                >
                  <Box
                    style={{
                      opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                      pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                    }}
                    xs={6}
                    md={12}
                    className="orientationPayout"
                  >
                    <Box>
                      <BetHandle
                        images={straightBetButton}
                        className={"actionButtonHandleBet3"}
                        text={"Straight Bet"}
                        clickBetButton={handleStraightBet}
                      />
                    </Box>
                    <Box className="straightPayout3">
                      <p>Payout</p>
                      <p>
                        {process.env.REACT_APP_MEGA3_STRAIGHT_PAYOUT_KEY} :{" "}
                        {process.env.REACT_APP_MEGA3_STRAIGHT_PAYOUT_VALUE}
                      </p>
                    </Box>
                  </Box>
                  <Box
                    className="playerStraightBet3"
                    style={{
                      opacity:
                        (betDisable === "StraightBet" ? "1" : "0.5") ||
                        (betDisable === "Confirm_Bet" ? "0.5" : ""),
                      pointerEvents:
                        betDisable === "Confirm_Bet" ? "none" : "auto",
                    }}
                  >
                    <Box>
                      <p className="textStraightBet3">Straight Bet</p>
                    </Box>
                    <Box
                      className={`pluseMinusstraightBox ${
                        activeBet !== "StraightBet" ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="minusStraight-Bet3"
                        onClick={() => {
                          if (showMute === false) {
                            playSoftButtonClickAudio();
                          }
                          if (!disableStraightCount) {
                            straightBetCount >= 1
                              ? handleCount(straightBetCount - 1)
                              : handleCount(straightBetCount);
                          }
                        }}
                      >
                        <RemoveIcon />
                      </button>
                      <input
                        type="number"
                        className="zeroStraight-Bet3"
                        value={straightBetCount}
                        onChange={(event) =>
                          setStraightBetCount(Number(event.target.value))
                        }
                        disabled={disableStraightCount}
                      />
                      <button
                        className="plusStraight-Bet3"
                        onClick={() => {
                          if (showMute === false) {
                            playSoftButtonClickAudio();
                          }
                          if (!disableStraightCount) {
                            handleCount(straightBetCount + 1);
                          }
                        }}
                      >
                        <AddIcon />
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid className="Red-Blue-Inbox">
                  <Box
                    className="Red-Blue-green-Inbox-first"
                    style={{
                      opacity:
                        (betDisable === "StraightBet" ? "1" : "0.5") &&
                        (betDisable === "Confirm_Bet"
                          ? ""
                          : betDisable === "StraightBet"
                          ? "1"
                          : "0.5"),
                    }}
                  >
                    {[...new Array(9)].map((a, i) => (
                      <Box
                        key={i}
                        className={`inputBoxGroup ${
                          currentInput === i ? "active" : ""
                        }`}
                        onClick={() =>
                          selectCurrentBox(
                            currentInput,
                            i,
                            straightBetData,
                            setCurrentInput
                          )
                        }
                      >
                        <Box
                          className={
                            redNumber > -1 &&
                            redNumber == straightBetData?.[i]?.[0]
                              ? "historybox-StraightBet3-red redNumberResult"
                              : "historybox-StraightBet3-red"
                          }
                        >
                          {straightBetData?.[i]?.[0]}
                        </Box>
                        <Box
                          className={
                            greenNumber > -1 &&
                            greenNumber == straightBetData?.[i]?.[1]
                              ? "historybox-StraightBet3-green redNumberResult"
                              : "historybox-StraightBet3-green"
                          }
                        >
                          {straightBetData?.[i]?.[1]}
                        </Box>
                        <Box
                          className={
                            blueNumber > -1 &&
                            blueNumber == straightBetData?.[i]?.[2]
                              ? "historybox-StraightBet3-blue redNumberResult"
                              : "historybox-StraightBet3-blue"
                          }
                        >
                          {straightBetData?.[i]?.[2]}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Grid className="orientationRumblito3Box">
                <Grid
                  display={"flex"}
                  marginTop={"4px"}
                  gap={"20px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  className="orientationCreditBox"
                >
                  <Box
                    style={{
                      opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                    }}
                    className="orientationPayout"
                  >
                    <Box>
                      <BetHandle
                        images={rambolitoButton3}
                        className={"actionButtonHandleBet3"}
                        text={"rambolito 3 Bet"}
                        clickBetButton={handleRambolito3}
                        style={{
                          opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                          pointerEvents:
                            betDisable === "Confirm_Bet" ? "none" : "",
                        }}
                      />
                    </Box>
                    <Box className="rambolitoPayout3">
                      <p>Payout</p>
                      <p>
                        {process.env.REACT_APP_MEGA3_RUMBLE3_PAYOUT_KEY} :{" "}
                        {process.env.REACT_APP_MEGA3_RUMBLE3_PAYOUT_VALUE}
                      </p>
                    </Box>
                  </Box>
                  <Box
                    className="playerRambilitoBet3"
                    style={{
                      opacity:
                        (betDisable === "rambolitoPayout3" ? "1" : "0.5") ||
                        (betDisable === "Confirm_Bet" ? "0.5" : ""),
                      pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                    }}
                  >
                    <Box>
                      <p className="textRambilitoBet3">rambolito 3 Bet</p>
                    </Box>
                    <Box
                      className={`countpluseMinusRambolito3 ${
                        activeBet !== "rambolitoPayout3" ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="minusRambilitoBet3"
                        onClick={() => {
                          if (showMute === false) {
                            playSoftButtonClickAudio();
                          }
                          if (!disableRamblito3Count) {
                            rambolito3Count >= 1
                              ? handleCount(rambolito3Count - 1)
                              : handleCount(rambolito3Count);
                          }
                        }}
                      >
                        <RemoveIcon />
                      </button>
                      <input
                        type="number"
                        className="zeroStraight-Bet3"
                        value={rambolito3Count}
                        onChange={(event) =>
                          setRambolito3Count(Number(event.target.value))
                        }
                        disabled={disableRamblito3Count}
                      />
                      <button
                        className="plusRambilitoBet3"
                        onClick={() => {
                          if (showMute === false) {
                            playSoftButtonClickAudio();
                          }
                          if (!disableRamblito3Count) {
                            handleCount(rambolito3Count + 1);
                          }
                        }}
                      >
                        <AddIcon />
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid className="Red-Blue-Inbox">
                  <Box
                    className="Red-Blue-green-Inbox-second"
                    style={{
                      opacity:
                        (betDisable === "rambolitoPayout3" ? "1" : "0.5") &&
                        (betDisable === "Confirm_Bet"
                          ? ""
                          : betDisable === "rambolitoPayout3"
                          ? "1"
                          : "0.5"),
                    }}
                  >
                    {[...new Array(9)].map((a, i) => {
                      let rambleDemoRes = rambolito3BetData[i] && [
                        redNumber,
                        greenNumber,
                        blueNumber,
                      ];
                      let rambleRes = rambolito3BetData[i]
                        ? rambolito3BetData[i]?.map((e) => {
                            let res = rambleDemoRes.includes(e);
                            if (res) {
                              let removeIndex = rambleDemoRes.findIndex(
                                (o) => o === e
                              );
                              rambleDemoRes.splice(removeIndex, 1);
                            }
                            return res;
                          })
                        : [false, false, false];
                      return (
                        <Box
                          key={i}
                          className={`inputBoxGroup ${
                            rumbalito3CurentInput === i ? "active" : ""
                          }`}
                          onClick={() =>
                            selectCurrentBox(
                              rumbalito3CurentInput,
                              i,
                              rambolito3BetData,
                              setRumbalito3CurentInput
                            )
                          }
                        >
                          <Box
                            className={
                              rambleRes[0]
                                ? "historybox-StraightBet3-red redNumberResult"
                                : "historybox-StraightBet3-red"
                            }
                          >
                            {rambolito3BetData?.[i]?.[0]}
                          </Box>
                          <Box
                            className={
                              rambleRes[1]
                                ? "historybox-StraightBet3-green redNumberResult"
                                : "historybox-StraightBet3-green"
                            }
                          >
                            {rambolito3BetData?.[i]?.[1]}
                          </Box>
                          <Box
                            className={
                              rambleRes[2]
                                ? "historybox-StraightBet3-blue redNumberResult"
                                : "historybox-StraightBet3-blue"
                            }
                          >
                            {rambolito3BetData?.[i]?.[2]}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
              <Grid className="orientationRumblito6Box">
                <Grid
                  display={"flex"}
                  marginTop={"4px"}
                  gap={"20px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  className="orientationCreditBox"
                >
                  <Box
                    style={{
                      opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                    }}
                    className="orientationPayout"
                  >
                    <Box>
                      <BetHandle
                        images={rambolitoBtn6}
                        className={"actionButtonHandleBet3"}
                        text={"rambolito 6 Bet"}
                        clickBetButton={handleRambolito6}
                        style={{
                          opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                          pointerEvents:
                            betDisable === "Confirm_Bet" ? "none" : "",
                        }}
                      />
                    </Box>
                    <Box className="rambolitoPayout6">
                      {" "}
                      <p>Payout</p>{" "}
                      <p>
                        {" "}
                        {process.env.REACT_APP_MEGA3_RUMBLE6_PAYOUT_KEY} :{" "}
                        {process.env.REACT_APP_MEGA3_RUMBLE6_PAYOUT_VALUE}
                      </p>{" "}
                    </Box>
                  </Box>
                  <Box
                    className="playerRambolitoBet6"
                    style={{
                      opacity:
                        (betDisable === "playerRambolitoBet6" ? "1" : "0.5") ||
                        (betDisable === "Confirm_Bet" ? "0.5" : ""),
                      pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                    }}
                  >
                    <Box>
                      <p className="textRumbleBet6">rambolito 6 Bet</p>
                    </Box>
                    <Box
                      className={`pluseMinusRumbolito6 ${
                        activeBet !== "playerRambolitoBet6" ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="minusRumbolito6"
                        onClick={() => {
                          if (showMute === false) {
                            playSoftButtonClickAudio();
                          }
                          if (!disableRamblito6Count) {
                            rambolito6Count >= 1
                              ? handleCount(rambolito6Count - 1)
                              : handleCount(rambolito6Count);
                          }
                        }}
                      >
                        <RemoveIcon />
                      </button>
                      <input
                        type="number"
                        className="zeroStraight-Bet3"
                        value={rambolito6Count}
                        onChange={(event) =>
                          setRambolito6Count(Number(event.target.value))
                        }
                        disabled={disableRamblito6Count}
                      />
                      <button
                        className="plusRumbolito6"
                        onClick={() => {
                          if (showMute === false) {
                            playSoftButtonClickAudio();
                          }
                          if (!disableRamblito6Count) {
                            handleCount(rambolito6Count + 1);
                          }
                        }}
                      >
                        <AddIcon />
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid className="Red-Blue-Inbox">
                  <Box
                    className="Red-Blue-green-Inbox-third"
                    style={{
                      opacity:
                        (betDisable === "playerRambolitoBet6" ? "1" : "0.5") &&
                        (betDisable === "Confirm_Bet"
                          ? ""
                          : betDisable === "playerRambolitoBet6"
                          ? "1"
                          : "0.5"),
                    }}
                  >
                    {[...new Array(9)].map((a, i) => (
                      <Box
                        key={i}
                        className={`inputBoxGroup ${
                          rumbalito6CurentInput === i ? "active" : ""
                        }`}
                        onClick={() =>
                          selectCurrentBox(
                            rumbalito6CurentInput,
                            i,
                            rambolito6BetData,
                            setRumbalito6CurentInput
                          )
                        }
                      >
                        <Box
                          className={
                            redNumber > -1 &&
                            redNumber == rambolito6BetData?.[i]?.[0]
                              ? "historybox-StraightBet3-red redNumberResult"
                              : "historybox-StraightBet3-red"
                          }
                        >
                          {rambolito6BetData?.[i]?.[0]}
                        </Box>
                        <Box
                          className={
                            greenNumber > -1 &&
                            greenNumber == rambolito6BetData?.[i]?.[1]
                              ? "historybox-StraightBet3-green redNumberResult"
                              : "historybox-StraightBet3-green"
                          }
                        >
                          {rambolito6BetData?.[i]?.[1]}
                        </Box>
                        <Box
                          className={
                            blueNumber > -1 &&
                            blueNumber == rambolito6BetData?.[i]?.[2]
                              ? "historybox-StraightBet3-blue redNumberResult"
                              : "historybox-StraightBet3-blue"
                          }
                        >
                          {rambolito6BetData?.[i]?.[2]}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                className="confirmationButtonMega3"
                sx={{ display: { xs: "flex", lg: "none" } }}
              >
                <Box className="actionBox1">
                  <ActionBtnMega3ball
                    Images={clearImage}
                    text="CLEAR"
                    className={"actionButton"}
                    handleActionButton={clearData}
                    number={straightBetData}
                  />
                  <ActionBtnMega3ball
                    Images={totalPayoutBtn}
                    text="total "
                    text2=" ₱ "
                    text3={totalBetAmount}
                    className={"totalPayoutBtn"}
                    style={{ width: "100%" }}
                    handleActionButton={amountShow}
                  />
                  <ActionBtnMega3ball
                    Images={luckyPickImage}
                    text="LUCKY PICK"
                    className={"luckyPickImage"}
                    handleActionButton={() => handleluckypick()}
                    number={
                      betDisable === "StraightBet"
                        ? straightBetData
                        : betDisable === "rambolitoPayout3"
                        ? rambolito3BetData
                        : rambolito6BetData
                    }
                  />
                  <ActionBtnMega3ball
                    Images={confirmImage}
                    text="CONFIRM"
                    className={"actionButton"}
                    handleActionButton={handleButtonConfirm}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              className="confirmationButtonMega3 landscapeConfirmation"
              sx={{ display: { xs: "flex", lg: "none" } }}
            >
              <Box className="actionBox1">
                <ActionBtnMega3ball
                  Images={clearImage}
                  text="CLEAR"
                  className={"actionButton"}
                  handleActionButton={clearData}
                  number={straightBetData}
                  style={{
                    opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                    pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                  }}
                />
                <ActionBtnMega3ball
                  Images={totalPayoutBtn}
                  text="total "
                  text2=" ₱ "
                  text3={totalBetAmount}
                  className={"totalPayoutBtn"}
                  handleActionButton={amountShow}
                />
                <ActionBtnMega3ball
                  style={{
                    opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                    pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                  }}
                  Images={luckyPickImage}
                  text="LUCKY PICK"
                  className={"luckyPickImage"}
                  handleActionButton={() => handleluckypick()}
                  number={
                    betDisable === "StraightBet"
                      ? straightBetData
                      : betDisable === "rambolitoPayout3"
                      ? rambolito3BetData
                      : rambolito6BetData
                  }
                />
                <ActionBtnMega3ball
                  style={{
                    opacity: betDisable === "Confirm_Bet" ? "0.5" : "",
                    pointerEvents: betDisable === "Confirm_Bet" ? "none" : "",
                  }}
                  Images={confirmImage}
                  text="CONFIRM"
                  className={"actionButton"}
                  handleActionButton={handleButtonConfirm}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Index;
