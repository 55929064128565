import React from 'react';
import { Box, Typography } from '@mui/material';
import { convertToInternationalCurrencySystem } from '../../services/currencyConverter.js';
export const WalletBalance = ({ Images, image2, typo, className,   text3, text4, sx, image2css, ActionButtonMega2Ball,textCoin }) => {

    return (
        <Box lg={1} className={className} sx={sx}>
            <img src={Images} alt="Images" className={ActionButtonMega2Ball} />
            <Typography
                variant="body2"
                sx={{ fontSize: { xs: '14px', sm: '18px', lg: '22px' }, width: "100%" }}
                className={typo}
            >
                <img src={image2} alt="" className={image2css} />
                <div className={textCoin}>
                    <span className="text3">{text3}</span>
                    <span className="text4">{convertToInternationalCurrencySystem(text4)}</span>
                </div>
            </Typography>
        </Box>
    )
}
