import { Box } from "@mui/material";
import React, { useEffect } from "react";
import bg from "../images/bg-1.jpg";
import { ChatListMega } from "../APIHandler/OperatorAPI";
import moment from "moment";
import { useRef } from "react";
import Connection from "../services/SignalRConnectionHub";
import { useState } from "react";

const ChatViewer = ({ game }) => {
  console.log({game})
  const [chats, setchats] = useState([]);
  useEffect(() => {
    Connection.on("SendMessageMegaTwo", (chatMessage) => {
      setchats((prev) => [...prev, chatMessage]);
    });
    chatList();
  }, []);
  const chatList = async () => {
    let payload = game == "mega2" ? { gameId: 1 } : { gameId: 2 };
    console.log({game},payload)
    const response = await ChatListMega(payload);
    console.log({response})
    setchats(response?.data);
  };
  const messageEndRef = useRef(null);
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [chats]);
  return (
    <Box
      marginX={"85"}
      style={{
        backgroundImage: `url(${bg})`,
        minHeight: "100vh",
        marginTop: 0,
        backgroundSize: "cover",
        // overflow: "hidden"
      }}
    >
      <Box className="ChattingBox" style={{
        height:"90vh"
      }}>
        <button className="live1 ">Live</button>
        <Box className="chatbox chatboxScroll" style={{
        height:"82vh"
      }}>
          {chats.map((chat, i) => (
            <Box className="username">
              <Box className="emoji">
                <p className="emojiTag">{chat?.playerName?.split('')[0]}</p>
                <h2 className="mail">{chat.playerName}</h2>
                <p className="now">{moment(chat.dateTime).fromNow()}</p>
              </Box>

              <p className="chatting">{chat.message}</p>
            </Box>
          ))}
          <Box ref={messageEndRef} />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatViewer;
