import { Box, Grid, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import React from 'react'
import { WalletBalance } from '../../WalletBalance/index'
import imageLogo3 from "../../../images/Mega-3-logo.png";
import creditPoint from "../../../images/player mega-3/creadit.png";
import Coin from "../../../images/coin.png";
import historyImage from "../../../images/history.png";
import infoImage from "../../../images/info.png";
import switchGame from "../../../images/game-switch.png";
import audioImage from "../../../images/player mega-2/audio.png";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { MoreVert } from "@mui/icons-material";
import audioMuteImage from "../../../images/player mega-2/audio-mute.png";
import userProfileImage from "../../../images/profile.png";

const NavbarMega3 = ({ balance, anchorEl, open, handleClose, showMute, playerName, clickAudioImage,handleSwitchGame,handleGameInfo, handleHistoryIconBox,handleClick}) => {
    return (
        <Grid container justifyContent={"space-between"} className={"navbarMega3"}>
            <Box sx={{ maxWidth: { xs: '100px', md: '200px', lg: '300px' } }} className="logo-navbar">
                <img src={imageLogo3} alt="images" className="logo-mega3Ball" />
            </Box>
            {/* for collapsible menu in smaller devices */}
            {/* for tabs and mobile screens */}
            <WalletBalance
                sx={{ display: { xs: 'flex', lg: 'none' } }}
                Images={[creditPoint]}
                image2={Coin}
                text4={balance}
                textCoin={"textCoin"}
                image2css={"image2"}
                typo={"Name currencyContainer"}
                text3={"₱"}
                ActionButtonMega2Ball="ActionButtonMega2Ball"
                className={"creditMega2Ball"}
            />
            <Box className="userAccount"
                sx={{ display: { xs: 'flex', lg: 'none' } }}>
                <img
                    src={userProfileImage}
                    alt="userProfileImage"
                    className="userProfileIcon"
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={{ display: { xs: '', lg: 'none' } }}
                PaperProps={{
                    className: 'actionDropdown',
                    elevation: 0,
                    sx: {
                        backgroundColor: '#00232e',
                        border: '1px solid #2dc2f9',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 14,
                            right: -5,
                            width: 10,
                            height: 10,
                            bgcolor: '#00232e',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            borderRight: '1px solid #2dc2f9',
                            borderTop: '1px solid #2dc2f9'
                        },
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleClose("history")} >
                    <img
                        src={historyImage}
                        alt="historyImage"
                        className="historyImage"
                    /> History
                </MenuItem>
                <MenuItem onClick={() => handleClose("Info")} >
                    <img src={infoImage} alt="infoImage" className="infoImage" />Info
                </MenuItem>
                <MenuItem onClick={() => handleClose("Switch")} >
                    <img
                        src={switchGame}
                        alt="switchGame"
                        className="switchGameIcon"
                    />Switch
                </MenuItem>
                <MenuItem onClick={() => handleClose("Mute")} >
                    {showMute === false
                        ? <img src={audioImage} alt="audioImage" className="audioImage" />
                        : <VolumeOffIcon style={{ color: 'rgb(45 194 249)', height: '20px', width: '20px' }} />}
                    {showMute === false ? 'Mute' : 'Unmute'}
                </MenuItem>
            </Menu>
            <Tooltip title="Account settings"
                className="orientationMoreVert" >
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: { md: 2 }, display: { xs: 'block', lg: 'none' } }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <MoreVert style={{ color: "#fff" }} />
                </IconButton>
            </Tooltip>
            <Box className="switchGameBOx"
                sx={{ display: { xs: 'none', lg: 'flex' } }}>
                <Box
                    onClick={() => handleHistoryIconBox()}
                    className="historyIconBoxMega2Ball"
                >
                    <img src={historyImage} alt="historyImage" className="historyImage" />
                </Box>
                <Box onClick={() => handleGameInfo()} className="gameInfoBox"              >
                    <img src={infoImage} alt="infoImage" className="infoImage" />
                </Box>
                <Box onClick={() => handleSwitchGame()} className="clickSwitchGame"              >
                    <img src={switchGame} alt="switchGame" className="switchGameIcon" />
                </Box>
                <Box onClick={clickAudioImage} className="audioImageBox">
                    <img src={audioImage} alt="audioImage" className="audioImage" />
                    {showMute && (
                        <img
                            src={audioMuteImage}
                            alt="audioMuteImage"
                            className="audioMuteImage"
                        />
                    )}
                </Box>
                <Box className="userAccount">
                    <img
                        src={userProfileImage}
                        alt="userProfileImage"
                        className="userProfileImage"
                        title={playerName}
                    />
                    {playerName}
                </Box>
            </Box>
        </Grid>
    )
}

export default NavbarMega3