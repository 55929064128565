import { Route, Routes } from "react-router-dom";
import "./index.css";
import "./App.css";
import { Suspense, useRef } from "react";
import Mega2ball from "./player/Mega2ball";
import Mega3Ball from "./player/Mega3ball";
import LoginPage from "./loginPage/LoginPage";
import OpareatorMega2Ball from "./operator/Mega2Balls";
import OpareatorMega3Ball from "./operator/Mega3Balls";
import { isMobile } from "./component/BackdropRotate/BackdropRotate";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useState } from "react";
import Modal from "@mui/material/Modal";
// import { Button, Typography } from "@mui/material";
import clickOkImages from "./images/SwitchGame/yes-button.png";
import PopupButtonsHandler from "./component/PopupButtonsHandler";
// import ReuseModal from "./component/Modal/ReuseModal";
// import AddressBarVisibility from "./component/AddressBarVisibility";
import "tcplayer.js/dist/tcplayer.min.css";
import ChatViewer from "./chatViewer/ChatViewer";

const timeout = 10_000;
const promptBeforeIdle = 4_000;
const userAgent = navigator.userAgent.toLowerCase();

const isTablet =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  // backgroundColor: '#fff',
  border: "0px solid #000",
  boxShadow: 24,
  // padding: 14,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "15px",
  backgroundImage: "url(/images/Pop-up-bg.png)",
};

function App() {

  const createRipple = (e) => {
    if (isMobile.any() || isTablet) {
      const ripple = document.createElement("div");
      ripple.className = "ripple";
      ripple.style.top = e.clientY - 12.5 + "px";
      ripple.style.left = e.clientX - 12.5 + "px";
      document.body.appendChild(ripple);
      setTimeout(() => {
        ripple.remove();
      }, 700 );
    }
  };

  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(10_000);
  const [openModal, setOpenModal] = useState(false);
  let startTimer;
  let executionTime;

  const onIdle = () => {
    const startTime = performance.now();
    startTimer = setTimeout(() => {
      setOpenModal(true);
      const endTime = performance.now();
      executionTime = endTime - startTime; // Calculate the execution time
    }, 50 * 10000); //for 5 minutes
  };
  // console.log(Date.now())
  useEffect(() => {
    if (remaining == 0) {
      window.location.reload();
    }
  }, [remaining]);

  const onActive = () => {
    clearTimeout(startTimer);
    // setOpenModal(false);
  };
  const onPrompt = () => {
    setOpenModal(true);
  };
  const onAction = () => {
    setCount(count + 1);
  };
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    // onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });
  useEffect(() => {
    if(openModal){
    const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
      
      return () => {
        clearInterval(interval);
      };
    }
  });

  const handleStillHere = () => {
    activate();

    clearTimeout(startTimer);
    setOpenModal(false);
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            background: "#000",
            height: "100%",
            width: "100%",
            color: "#fff",
          }}
        >
          Loading...
        </div>
      }
    >
      <div onClick={createRipple} id="content" > 
      {/* {<AddressBarVisibility/>? 'hidden' : 'visible'}   */}
        <Modal
          open={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={style}>
            <div className="modalTimer">
              <h3>Are you still here?</h3>
              <p>Logging out in {remaining} seconds</p>
              <PopupButtonsHandler
                Images={clickOkImages}
                style={{fontSize: "11px"}}
                text={"I'm still heres"}
                className="ClickOkayImageEndSession"
                clickOkayPopup={() => handleStillHere()}
              />
            </div>
          </div>
        </Modal>
        <Routes>
          <Route
            exact
            path="/operator/mega2spin"
            element={<OpareatorMega2Ball />}
          />
          <Route path="/operator/mega3spin" element={<OpareatorMega3Ball />} />
          <Route path="/player/Mega2Spin" element={<Mega2ball />} />
          <Route path="/player/Mega3Spin" element={<Mega3Ball />} />
          <Route path="/operatorLogin" element={<LoginPage />} />
          <Route path="/chatViewer/mega2spin" element={<ChatViewer game={"mega2"}/>}/>
          <Route path="/chatViewer/mega3spin" element={<ChatViewer game={"mega3"}/>}/>
          <Route path="/" element={<LoginPage />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
