import {
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import userProfileImage from "../../images/profile.png";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./index.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import bg from "../../images/bg-1.jpg";
import straightBetImages from "../../images/Stragiht-bet.png";
import rumbleBetImage from "../../images/Rumble-bet.png";
import redball from "../../images/red_ball1.png";
import RoundBall from "../../component/RoundBall";
import blueBall from "../../images/bule_ball1.png";
import blueButton from "../../images/Blue_button1.png";
import PointBox from "../../component/PointBox";
import redButton from "../../images/Red_button1.png";
import lineLeft from "../../images/1st-line-left.png";
import lineRight from "../../images/1st-line-Right.png";
import clearImage from "../../images/clear.png";
import luckyPickImage from "../../images/lucky-pick1.png";
import confirmImage from "../../images/Confirm.png";
// import imageLogo2 from "../../images/Mega-2-logo.png";
import imageLogo2 from "../../images/New-Mega-2-logo.jpeg";
import chatImg from "../../Asset/mobile/chat.png";
import ActionButtonMega2Ball from "../../player/Mega2ball/ActionButtonMega2Ball";
import switchGame from "../../images/game-switch.png";
import infoImage from "../../images/info.png";
import historyImage from "../../images/history.png";
import underlineHistory from "../../images/player mega-2/underline-history.png";
import creditPoint from "../../images/player mega-2/credit.png";
import totalPayment from "../../images/player mega-2/total.png";
import rumblePayout from "../../images/player mega-2/Rumble-payout.png";
import straightPayout from "../../images/player mega-2/straight-payout.png";
import Coin from "../../images/coin.png";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {
  ChatApiPlayer2,
  ConfirmPlaceBet,
  GameHistoryMega2Ball,
  ReloadPagePlayerMega2Ball,
  WinnerCalculationPlayerMega2Ball,
  cancelRound,
  playerHistoryMega2Ball,
  validateUserSessionID,
} from "../../APIHandler/API";
import {
  StartTimer,
  ChatListMega,
  SettleRoundMega2,
} from "../../APIHandler/OperatorAPI";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  submitResultForOperatorMega2Ball,
  warningMsg,
} from "../../services/helpers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Connection from "../../services/SignalRConnectionHub";
import okayButtonInfoPayout from "../../images/InfoScreen/oky-button.png";
import audioImage from "../../images/player mega-2/audio.png";
import audioMuteImage from "../../images/player mega-2/audio-mute.png";
import moment from "moment";
import InputEmoji from "react-input-emoji";
import { MoreVert } from "@mui/icons-material";
import CryptoJS from "crypto-js";
import SoftButtonClickAudio from "../../Asset/audio/SoftGameButtonClick.mp3";
import TribalNotificationAudio from "../../Asset/audio/TribalNotification.mp3";
import SoftNegativeToneAudio from "../../Asset/audio/SoftNegativeTone.mp3";
import PlayfulMusicalGameEndingAudio from "../../Asset/audio/GameEnding/PlayfulMusicalGameEnding.mp3";
import PopupAudio from "../../Asset/audio/Popup.mp3";
import CombinationAudio from "../../Asset/audio/Pop02.mp3";
import MagicBonusReveal from "../../Asset/audio/magic_bonus_reveal.mp3";
import RevealAudio from "../../Asset/audio/magic_bonus_reveal.mp3";
import useSound from "use-sound";
import { BetHeader, ShowBoxes } from "../../component/ShowBoxes";
import { WalletBalance } from "../../component/WalletBalance";
import { InactiveUser } from "../../component/UI/Modal/InactiveUser";
import WinnerModal from "../../component/UI/Modal/WinnerModal";
import { LoserModal } from "../../component/UI/Modal/LoserModal";
import PausedModal from "../../component/UI/Modal/PausedModal";
import EndSessionModal from "../../component/UI/Modal/EndSessionModal";
import SwitchGame from "../../component/UI/Modal/SwitchGame";
import HistoryModal from "../../component/UI/Modal/HistoryModal";
import RulesModal from "../../component/UI/Modal/RulesModal";
import SameDayGameHistory from "../../component/SameDayGameHistory/SameDayGameHistory";
import PlayerGameHistoryIndex from "../../component/PlayerGameHistory/PlayerGameHistoryIndex";
import BackdropRotate, {
  isMobile,
} from "../../component/BackdropRotate/BackdropRotate";
import TCPlayer from "tcplayer.js";

import landscapeSwipeUP from "../../Asset/mobile/landscape-Swipe-UP.gif";

function Index() {
  const [activeGame, setActiveGame] = useState("straightBet");
  const [straightData, setStraightData] = useState([]);
  const [rumbleData, setRumbleData] = useState([]);
  const [SwitchModalOpen, setSwitchModalOpen] = useState(false);
  const [gameHistoryOpen, setGameHistoryOpen] = useState(false);
  const [gameInfo, setGameInfo] = useState(false);
  const [curentInput, setCurentInput] = useState(0);
  const [curentRumbleInput, setCurentRumbleInput] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [todayGameHistory, setTodayGameHistory] = useState([]);
  const [roundRedBallData, setRoundRedBallData] = useState("0");
  const [roundBlueBallData, setRoundBlueBallData] = useState("0");
  const [countStraightBet, setCountStraightBet] = useState(0);
  const [countRumbleBet, setCountRumbleBet] = useState(0);
  const [disableRedBox, setDisableRedBox] = useState(false);
  const [disableBlueBox, setDisableBlueBox] = useState(true);
  const [straightBetDisable, setStraightBetDisable] = useState(false);
  const [flagTosetDataReload,setFlagTosetDataReload]=useState(false);
  const [showBox, setShowBox] = useState("gameRuleBox");
  const [showMute, setShowMute] = useState(
    JSON.parse(localStorage.getItem("showMute"))
  );
  const [gameSessionId, setGameSessionId] = useState();
  const [sessionId, setSessionId] = useState(0);
  const [showHistory, setShowHistory] = useState(true);
  const [showChatbox, setShowChatbox] = useState(false);
  const [messages, setMessages] = useState("");
  const [params] = useSearchParams();
  const [isRunning, setIsRunning] = useState(true);
  const winningAmount = useRef("-");
  const [resultPopup, setResultPopup] = useState(false);
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [timerStopPopup, setTimerStopPopup] = useState(false);
  const [resumedTimerPopup, setResumedTimerPopup] = useState(false);
  const [endSessionPopup, setEndSessionPopup] = useState(false);
  const [congratsShowHide, setCongratsShowHide] = useState(false);
  const [allButtonDisable, setAllButtonDisable] = useState(false);
  // const [totalBetAmount, setTotalBetAmount] = useState(0);
  const [chats, setchats] = useState([]);
  const [submitState, setSubmitState] = useState(""); //AUTO_SUBMIT OR BUTTON
  //clear and submit button will dactive while minute will be 00
  const [buttonsActive, setButtonsActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [playerId, setPlayerId] = useState();
  const [playerName, setPlayerName] = useState();
  const [balance, setBalance] = useState(0);
  const [playerStatus, setPlayerStatus] = useState(false);
  const [disableStraightCount, setDisableStraightCount] = useState(false);
  const [disableRumbleCount, setDisableRumbleCount] = useState(false);
  const [historyType, setHistoryType] = useState(true);
  const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
  const [playTribalNotificationAudio] = useSound(TribalNotificationAudio);
  const [playSoftNegativeToneAudio] = useSound(SoftNegativeToneAudio);
  const [playPopupAudio] = useSound(PopupAudio);
  const [playPlayfulMusicalGameEndingAudio] = useSound(
    PlayfulMusicalGameEndingAudio
  );
  const [playCombinationAudio] = useSound(CombinationAudio);
  const [playResultRevealAudio] = useSound(MagicBonusReveal);
  const [straightBetClickStatus, setstraightBetClickStatus] = useState(true);
  const audio = useRef();
  const currentPlayerId = useRef(0);
  const [showSwipeUp, setShowSwipeUp] = useState("hideSwipeUp");
  const [addressBarVisible, setAddressBarVisible] = useState(false);
  const [loading, setLoading] = useState(""); // State to manage loading

  let TimerStarted;
  const GameIsPlaying = useRef(false);
  const GAME_S_ID = useRef();
  let x = JSON.parse(localStorage.getItem("showMute"));
  if (x !== true && x !== false) {
    localStorage.setItem("showMute", false);
    setShowMute(false);
  }
  const handleClick = (event) => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    document.querySelector(".chatBox-playerPage").classList.remove("test");
    document.querySelector(".liveStreamingImage").classList.remove("test");
    document.querySelector(".red-And-BlueBox").classList.remove("test");
    switch (value) {
      case "history":
        handleHistoryIconBox();
        break;
      case "Switch":
        handleSwitchGame();
        break;
      case "Mute":
        clickAudioImage();
        break;
      case "Info":
        handleGameInfo();
        break;
    }
    setAnchorEl(null);
  };
  function enableChat() {
    document.querySelector(".chatBox-playerPage").classList.toggle("test");
    document.querySelector(".liveStreamingImage").classList.toggle("test");
    document.querySelector(".red-And-BlueBox").classList.toggle("test");
  }

  const content = useRef(null);

  const enterFullScreen = () => {
    let elem=document.documentElement;
    // console.log("enterfullscreen==",content.current,elem);
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE/Edge
        elem.msRequestFullscreen();
      }
    }
  };
  
  const swipeUpBoxRef = useRef(null);
  useEffect(() => {
    if (isMobile.any()) {
      setShowSwipeUp("showSwipeUp");
    }
    const swipeUpBox = swipeUpBoxRef.current;

    const handleTouchEnd = (event) => {
      event.preventDefault(); // Prevent default behavior if necessary
      setShowSwipeUp("hideSwipeUp"); // Call your function
      enterFullScreen();
    };
    if (swipeUpBox) {
      swipeUpBox.addEventListener("touchend", handleTouchEnd);
      swipeUpBox.addEventListener("ondragend", handleTouchEnd);
      swipeUpBox.addEventListener("touchmove", handleTouchEnd);
      swipeUpBox.addEventListener("click", handleTouchEnd);
    
    }
  }, []);
  const checkResult = async (_, gameSID) => {
    const valuee = {
      playerId: playerId ?? currentPlayerId.current,
      gameSessionId: gameSessionId ? gameSessionId : gameSID,
      token: params.get("token"),
    };
    const reloadPayload = {
      sessionId: gameSID,
      token: params.get("token"),
    };
    try {
      const res1 = await ReloadPagePlayerMega2Ball(reloadPayload);
  
      let strtLength = res1.data?.streghtBet?.bets?.length;
      let strtCount =
        Number(res1.data?.streghtBet?.betAmount) /
        Number(res1.data?.streghtBet?.numberOfBets || 1);
      let ramLength = res1.data?.rumbelBet?.bets?.length;
      let ramCount =
        Number(res1.data?.rumbelBet?.betAmount) /
        Number(res1.data?.rumbelBet?.numberOfBets || 1);
      const response = await WinnerCalculationPlayerMega2Ball(valuee);

      if (
        (strtCount > 0 && strtLength > 0) ||
        (ramCount > 0 && ramLength > 0)
      ) {
        if (response?.straight != 0 || response?.rumble != 0) {
          winningAmount.current = response?.amount;

          submitResultForOperatorMega2Ball("congratulations");
          if (showMute === false) {
            audio.current = playTribalNotificationAudio;
            new Audio(TribalNotificationAudio).play();
          }
          setCongratsShowHide(true);
          setTimeout(() => {
            setCongratsShowHide(false);
          }, 3000);
        } else {
          setResultPopup(true);
          if (showMute === false) {
            audio.current = playSoftNegativeToneAudio;
            new Audio(SoftNegativeToneAudio).play();
          }
          setTimeout(() => {
            setResultPopup(false);
          }, 3000);
        }
      } else {
        if (showMute === false) {
          audio.current = playResultRevealAudio;
          new Audio(RevealAudio).play();
        }
      }
      // }
    } catch (error) {
      warningMsg("Error while getting winner declaration");
    }
  };

  function ShowPopup(message, data) {
    clearInterval(TimerStarted);
    TimerStarted = setTimeout(async () => {
      if (message.timerState === "Play") {
        if (
          roundRedBallData == "00" &&
          roundBlueBallData == "00" &&
          totalBetAmount > 0
        ) {
          let data = {
            token: params.get("token"),
            playerGameRoundId: localStorage.getItem("playerGameRoundId"),
            gameId: 1,
          };
          cancelRoundMega2(data);
        }
        if (GAME_S_ID.current != data.gameSessionId) {
          setSessionId(data.gameSessionId);
          //reset data
          const res1 = await StartTimer();
              // console.log("res1",res1)
          GAME_S_ID.current = res1?.gameSessionId;
          setGameSessionId(res1?.gameSessionId);
          setAllButtonDisable(false);
          setCountStraightBet(0);
          setCountRumbleBet(0);
          setCurentInput(0);
          setStraightData([]);
          setCurentRumbleInput(0);
          setRumbleData([]);
          setDisableBlueBox(true);
          setDisableRedBox(false);
          setActiveGame("straightBet");
          setRoundRedBallData("00");
          setRoundBlueBallData("00");
          setStraightBetDisable(false);
          setSubmitState("");
     
          if (res1?.result?.message === "Game Session Ended") {
            setEndSessionPopup(true);
            setAllButtonDisable(true);
          }
          HistoryFunction();
          GameHistoryFunc();
          //Wallet balance of user
          validateUserSession();
        }
        setTimerStopPopup(false);
        if (GameIsPlaying.current) {
          setResumedTimerPopup(true);
          GameIsPlaying.current = false;
        }
        setTimeout(() => {
          setResumedTimerPopup(false);
        }, 2000);
      } else if (message.timerState === "Pause") {
        //Add sound here for pause popup
        setTimerStopPopup(true);
        setResumedTimerPopup(false);
      }
    }, 1000);
  }
  useEffect(() => {
    straightData.length > 0 && countStraightBet > 0
      ? setDisableStraightCount(true)
      : setDisableStraightCount(false);
  }, [straightData.length]);
  useEffect(() => {
    rumbleData.length > 0 && countRumbleBet > 0
      ? setDisableRumbleCount(true)
      : setDisableRumbleCount(false);
  }, [rumbleData.length]);
  useEffect(() => {
    if (activeGame === "straightBet") {
      if (straightData[curentInput]?.length < 2) {
        setstraightBetClickStatus(true);
      }
      if (
        straightData[curentInput]?.length == 2 ||
        straightData[curentInput]?.length == 0
      ) {
        setstraightBetClickStatus(false);
      } else if (straightData?.length == 16) {
        setstraightBetClickStatus(false);
      }
    } else {
      if (rumbleData[curentRumbleInput]?.length < 2) {
        setstraightBetClickStatus(true);
      }
      if (
        rumbleData[curentRumbleInput]?.length == 2 ||
        rumbleData[curentRumbleInput]?.length == 0
      ) {
        setstraightBetClickStatus(false);
      } else if (rumbleData?.length == 16) {
        setstraightBetClickStatus(false);
      }
    }
  }, [
    straightData,
    straightData.filter((e) => e.length == 2).length,
    straightData[curentInput]?.length,
    rumbleData,
    rumbleData[curentRumbleInput]?.length,
    rumbleData.filter((e) => e.length == 2).length,
  ]);
  //Connect to the signalR
  useEffect(() => {
    Connection.on("TimerStateMegaTwo", (message, data) => {
      ShowPopup(message, data);
    });
    Connection.on("timerUpdate", (message) => {
      setMinutes(`${message}`?.split(":")[0]);
      setSeconds(`${message}`?.split(":")[1]);
      setIsRunning(true);
      if (message === "01:00") {
        warningMsg("Bet is closed");
        setAllButtonDisable(true);
      }
      if (message === "01:30") {
        warningMsg("Hurry up! Bet will close soon");
      }
    });
    Connection.on("ResultMegaTwoBall", (data) => {
      setRoundRedBallData(data.results?.[0]);
      setRoundBlueBallData(data.results?.[1]);
      GAME_S_ID.current = data?.gameSessionId;
      setGameSessionId(data?.gameSessionId);
      checkResult(true, data?.gameSessionId);
    });
    Connection.on("SessionEnded", (data) => {
      setEndSessionPopup(true);
      if (JSON.parse(localStorage.getItem("showMute")) === false) {
        playPlayfulMusicalGameEndingAudio();
      }
      setAllButtonDisable(true);
    });
    Connection.off();
    Connection.on("SendMessageMegaTwo", (chatMessage) => {
      setchats((prev) => [...prev, chatMessage]);
    });
  }, []);
  const clickOkPaused = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
  };
  const chatList = async () => {
    const response = await ChatListMega({ gameId: 1 });
    setchats(response?.data);
  };
  //Validate Members Identification
  const validateUserSession = async () => {
    let payload = params.get("token");
    let payload1 = { token: payload };
    const result = await validateUserSessionID(payload1);
    if (result) {
      currentPlayerId.current = result?.data?.data?.data?.id;
      setPlayerId((prev) => result?.data?.data?.data?.id);
      setPlayerName((prev) => result?.data?.data?.data?.name);
      setPlayerStatus((prev) => result?.data?.data?.data?.status != "ACTIVE");
      setBalance((prev) => result?.data?.data?.data?.balance);
    } else {
      warningMsg("Error occured while getting player's detail");
    }
  };
  //startTimer For Mega 2 Ball in player Page
  useEffect(() => {
    const StartTiming = async () => {
      const res = await StartTimer();
      setMinutes(res?.timer ? `${res?.timer}`?.split(":")[0] : "00");
      setSeconds(res?.timer ? `${res?.timer}`?.split(":")[1] : "00");
      GAME_S_ID.current = res?.gameSessionId;
      setGameSessionId(res?.gameSessionId);
      setSessionId(res?.result?.gameSessionId);
      if (res?.result?.message === "Game Session Ended") {
        setEndSessionPopup(true);
        setAllButtonDisable(true);
      }
      if (res?.result?.result?.[0] >= 0 && res?.result?.result?.[1] >= 0) {
        setRoundRedBallData(`${res?.result?.result?.[0]}`);
        setRoundBlueBallData(`${res?.result?.result?.[1]}`);
        checkResult(true, gameSessionId);
      }
    };
    StartTiming();
    validateUserSession();
    chatList();
  }, []);
  useEffect(() => {
    const StartTiming = async () => {
      const res = await StartTimer();
      setMinutes(res?.timer ? `${res?.timer}`?.split(":")[0] : "00");
      setSeconds(res?.timer ? `${res?.timer}`?.split(":")[1] : "00");
      GAME_S_ID.current = res?.gameSessionId;
      setGameSessionId(res?.gameSessionId);
      setSessionId(res?.result?.gameSessionId);
      if (res?.result?.message === "Game Session Ended") {
        setEndSessionPopup(true);
        setAllButtonDisable(true);
      }
      if (res?.result?.result?.[0] >= 0 && res?.result?.result?.[1] >= 0) {
        setRoundRedBallData(`${res?.result?.result?.[0]}`);
        setRoundBlueBallData(`${res?.result?.result?.[1]}`);
        checkResult(true, gameSessionId);
      }
    };
    StartTiming();
    validateUserSession();
    chatList();
  }, [timerStopPopup]);

  // Reload Data API when users refresh the page still data is showing within gameSession
  useEffect(() => {
    if (sessionId !== 0) {
      const ReloadDataFuncton = async () => {
        const value = {
          sessionId: gameSessionId,
          token: params.get("token"),
        };
        try {
          const response = await ReloadPagePlayerMega2Ball(value);
          localStorage.setItem("submit", false);
          //set the Number Of Bets for straight bet
          setCountStraightBet(
            Number(response.data?.streghtBet?.betAmount) /
              Number(response.data?.streghtBet?.numberOfBets || 1)
          );
          //set the Number Of Bets for rumble bet
          setCountRumbleBet(
            Number(response.data?.rumbelBet?.betAmount) /
              Number(response.data?.rumbelBet?.numberOfBets || 1)
          );
          //set the Total Bet-Amount
          // if (response.data?.rumbelBet?.bets !== null) {
          // }
          // let total =
          //   response.data?.streghtBet?.betAmount +
          //   response.data?.rumbelBet?.betAmount;
          //set the data in RUMBLE bet INPUT BOX
          if (response.data?.rumbelBet?.bets != null) {
            setRumbleData(response.data?.rumbelBet?.bets);
            setAllButtonDisable((prev) => true);
          }
          //set the data in STRAIGHT bet INPUT BOX
          if (response.data?.streghtBet?.bets !== null) {
           
            setStraightData(response.data?.streghtBet?.bets);
            setAllButtonDisable((prev) => true);
          }
        } catch (error) {
          warningMsg("Something went wrong");
        }
      };
      chatList();
      ReloadDataFuncton();
    }
  }, [sessionId,flagTosetDataReload]);
  //Handle setCountStraightBet
  const handleCountStraightBet = (value) => {
    let amount =
      curentInput * value + curentRumbleInput * countRumbleBet ||
      totalBetAmount;
    if (amount > balance) {
      warningMsg("Bet amount exceeds current balance");
      return;
    } else setCountStraightBet(value);
  };
  const handleCountRumbleBet = (value) => {
    let amount =
      curentInput * countStraightBet + curentRumbleInput * value ||
      totalBetAmount;
    if (amount > balance) {
      warningMsg("Bet amount exceeds current balance");
      return;
    } else setCountRumbleBet(value);
  };
  const cancelRoundMega2 = async (data) => {
    //Call cancel round
    const res2 = await cancelRound(data);
  };
  //Restart The Timer When (minutes === "00" && seconds === "00")
  useEffect(() => {
    const RestartTimer = async () => {
      if (minutes === "00" && seconds === "00") {
        const res1 = await StartTimer();
        GAME_S_ID.current = res1?.gameSessionId;
        setGameSessionId(res1?.gameSessionId);
        setSessionId(res1?.gameSessionId);
        setAllButtonDisable(false);
        setCountStraightBet(0);
        setCountRumbleBet(0);
        setCurentInput(0);
        setStraightData([]);
        setFlagTosetDataReload(!flagTosetDataReload)
      
        setCurentRumbleInput(0);
        setRumbleData([]);
        setDisableBlueBox(true);
        setDisableRedBox(false);
        setActiveGame("straightBet");
        setRoundRedBallData("00");
        setRoundBlueBallData("00");
        setStraightBetDisable(false);
        setSubmitState("");

        if (res1?.result?.message === "Game Session Ended") {
          setEndSessionPopup(true);
          setAllButtonDisable(true);
        }
        HistoryFunction();
        GameHistoryFunc();
        //Wallet balance of user

        validateUserSession();
      }
    };
    RestartTimer();
    const payload = {
      gameSessionId: gameSessionId,
      token: params.get("token"),
    };
    const settleRound = async () => {
      const res1 = await SettleRoundMega2(payload);
    };

    if (minutes === "00" && seconds === "01" && submitState != "") {
      if (
        roundRedBallData != "00" &&
        roundBlueBallData != "00" &&
        totalBetAmount > 0
      ) {
        settleRound();
      }
    }
    if ((minutes === "01" && seconds === "00") || minutes === "00") {
      setAllButtonDisable(true);
      //button deactive code untill time will be 00
      setButtonsActive(true);
    }

    if (minutes === "00" && seconds === "01") {
      if (
        roundRedBallData == "00" &&
        roundBlueBallData == "00" &&
        totalBetAmount > 0
      ) {
        let data = {
          token: params.get("token"),
          playerGameRoundId: localStorage.getItem("playerGameRoundId"),
          gameId: 1,
        };
        cancelRoundMega2(data);
      }
    }
  }, [minutes, seconds]);

  //Red Button clicked for mega 2 in player page
  const clickRedBox = (value) => {
    let amount =
      curentInput * countStraightBet + curentRumbleInput * countRumbleBet ||
      totalBetAmount;
    if (amount >= balance) {
      warningMsg("Bet amount exceeds current balance");
      return;
    }
    if (showMute === false) {
      playCombinationAudio();
    }
    if (activeGame === "straightBet") {
      if (straightData[curentInput]?.length === 2) {
        warningMsg("Current box is already filled!");
        return 1;
      } else {
        if (
          (straightData[curentInput] === null ||
            straightData[curentInput] === undefined ||
            straightData[curentInput]?.length < 2) &&
          curentInput < 15
        ) {
          const updatedData = [...straightData];
          updatedData[curentInput] = [value];
          setStraightData(updatedData);
          
        }
      }
      if (straightData[curentInput]?.length === 2 && curentInput >= 14) {
        if (curentInput > 14) setCurentInput((prev) => 14);
      } else {
        setDisableRedBox(!disableRedBox);
        setDisableBlueBox(!disableBlueBox);
      }
    } else {
      if (rumbleData[curentRumbleInput]?.length === 2) {
        warningMsg("Current box is already filled!");
        return 1;
      } else {
        if (
          (rumbleData[curentRumbleInput] === null ||
            rumbleData[curentRumbleInput] === undefined ||
            rumbleData[curentRumbleInput]?.length < 2) &&
          curentRumbleInput < 15
        ) {
          const updatedData = [...rumbleData];
          updatedData[curentRumbleInput] = [value];
          setRumbleData(updatedData);
        }
      }
      if (
        rumbleData[curentRumbleInput]?.length === 2 &&
        curentRumbleInput >= 14
      ) {
        if (curentRumbleInput > 14) setCurentRumbleInput((prev) => 14);
      } else {
        setDisableRedBox(!disableRedBox);
        setDisableBlueBox(!disableBlueBox);
      }
    }
  };
  //Blue Button clicked for mega 2 ball in player page
  const clickBlueBox = (value) => {
    let amount =
      curentInput * countStraightBet + curentRumbleInput * countRumbleBet ||
      totalBetAmount;
    if (amount >= balance) {
      warningMsg("Bet amount exceeds current balance");
      return;
    }
    if (showMute === false) {
      playCombinationAudio();
    }
    if (activeGame === "straightBet") {
      if (straightData[curentInput]?.length === 2) {
        warningMsg("Current box is already filled!");
        return 1;
      } else {
        if (
          (straightData[curentInput] === null ||
            straightData[curentInput] === undefined ||
            straightData[curentInput]?.length < 2) &&
          curentInput < 15
        ) {
          const updatedData = [...straightData];
          updatedData[curentInput].push(value);
          if (curentInput < 14) setCurentInput(curentInput + 1);
          setStraightData(updatedData);
         
        }
      }
      if (straightData[curentInput]?.length === 2 && curentInput >= 14) {
        if (curentInput > 14) setCurentInput((prev) => 14);
      } else {
        setDisableRedBox(!disableRedBox);
        setDisableBlueBox(!disableBlueBox);
      }
    } else {
      if (rumbleData[curentRumbleInput]?.length === 2) {
        warningMsg("Current box is already filled!");
        return 1;
      } else {
        const updatedData = [...rumbleData];
        updatedData[curentRumbleInput].push(value);
        if (curentRumbleInput < 14) setCurentRumbleInput(curentRumbleInput + 1);
        setRumbleData(updatedData);
      }
      if (
        rumbleData[curentRumbleInput]?.length === 2 &&
        curentRumbleInput >= 14
      ) {
        if (curentRumbleInput > 14) setCurentInput((prev) => 14);
      } else {
        setDisableRedBox(!disableRedBox);
        setDisableBlueBox(!disableBlueBox);
      }
    }
  };
  //StraightBet button clicked for mega 2 Ball in player pages.
  const handleStraightBet = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    if (activeGame === "rumbleBet") {
      if (straightData[straightData?.length - 1]?.length === 1) {
        setDisableBlueBox(false);
        setDisableRedBox(true);
      } else {
        setDisableBlueBox(true);
        setDisableRedBox(false);
      }
      setActiveGame("straightBet");
      setStraightBetDisable(false);
    }
  };
  //RumbleBet button clicked for mega 2 Ball in player pages.
  const handleRumbleBet = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    if (activeGame === "straightBet") {
      if (rumbleData[rumbleData?.length - 1]?.length === 1) {
        setDisableBlueBox(false);
        setDisableRedBox(true);
      } else {
        setDisableBlueBox(true);
        setDisableRedBox(false);
      }
      setActiveGame("rumbleBet");
      setStraightBetDisable(true);
    }
  };
  //Clear the data from input box for straightBet, rumbleBet
  const clearData = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    if (activeGame === "straightBet") {
      if (straightData.length) {
        if (curentInput > 0) {
          setCurentInput((prev) => prev - 1);
        }
        if (straightData[curentInput] != null) {
          setStraightData(
            straightData.map((data, i) => (i === curentInput ? [] : data))
          );
         
        }
      }
      setDisableRedBox(false);
      setDisableBlueBox(true);
    } else if (activeGame === "rumbleBet") {
      if (rumbleData?.length) {
        if (curentRumbleInput > 0) {
          setCurentRumbleInput((prev) => prev - 1);
        }
        if (rumbleData[curentRumbleInput] != null) {
          setRumbleData(
            rumbleData.map((data, i) => (i === curentRumbleInput ? [] : data))
          );
        }
      }
    }
  };
  // Lucky Pick number from StraightBet and RumbleBet
  const luckyPick = () => {
    setLoading("LUCKY_PICK");
    let amount = 0;
    if (activeGame == "rumbleBet") {
      amount =
        curentInput * countStraightBet +
          (curentRumbleInput + 1) * countRumbleBet || totalBetAmount;
    } else {
      amount =
        (curentInput + 1) * countStraightBet +
          curentRumbleInput * countRumbleBet || totalBetAmount;
    }
    if (amount > balance) {
      warningMsg("Bet amount will exceed current balance");
      setLoading("");
      return;
    }
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    const randomRedIndex = Math.floor(Math.random() * 35) + 1;
    const randomBlueIndex = Math.floor(Math.random() * 35) + 1;
    if (activeGame === "straightBet") {
      if (straightData[curentInput]?.length === 2) {
        warningMsg("Current box is already filled!");
        setLoading("");
        return 1;
      } else if (straightData[curentInput]?.length === 1) {
        straightData[curentInput].push(randomBlueIndex);
        setDisableRedBox(false);
      } else if (straightData[curentInput]?.length === 0) {
        straightData.splice(curentInput, 1, [randomRedIndex, randomBlueIndex]);
      } else {
        setStraightData((prevData) => [
          ...prevData,
          [randomRedIndex, randomBlueIndex],
        ]);
       
      }
      setCurentInput((prev) => (prev === 14 ? 14 : prev + 1));
    } else if (activeGame === "rumbleBet") {
      if (rumbleData[curentRumbleInput]?.length === 2) {
        warningMsg("Current box is already filled!");
        setLoading("");
        return 1;
      } else if (rumbleData[curentRumbleInput]?.length === 1) {
        rumbleData[curentRumbleInput].push(randomBlueIndex);
        setDisableRedBox(false);
      } else if (rumbleData[curentRumbleInput]?.length === 0) {
        rumbleData.splice(curentRumbleInput, 1, [
          randomRedIndex,
          randomBlueIndex,
        ]);
      } else {
        setRumbleData((prevData) => [
          ...prevData,
          [randomRedIndex, randomBlueIndex],
        ]);
      }
      setCurentRumbleInput((prev) => (prev === 14 ? 14 : prev + 1));
    }
    setLoading("");
  };
  const validateGame = () => {
    let str_length = straightData.filter((e) => e.length == 2)?.length;
    let rum_length = rumbleData.filter((e) => e.length == 2)?.length;

    const straightCondition = countStraightBet > 0 && str_length > 0;
    const rumbleCondition = countRumbleBet > 0 && rum_length > 0;

    if (
      rum_length == 0 &&
      countStraightBet < 1 &&
      countRumbleBet < 1 &&
      str_length == 0
    ) {
      warningMsg("Please Select Data To Place a Bet");
      return 1;
    } else if (straightCondition || rumbleCondition) {
      return 0;
    } else if (countStraightBet > 0 || str_length > 0) {
      if (str_length == 0) {
        warningMsg("Please select a pair of numbers from STRAIGHT BET");
        return 1;
      } else {
        warningMsg("Please select STRAIGHT BET amount");
        return 1;
      }
    } else if (countRumbleBet > 0 || rum_length > 0) {
      if (rum_length == 0) {
        warningMsg("Please select a pair of numbers from RUMBLE BET");
        return 1;
      } else {
        warningMsg("Please select RUMBLE BET amount ");
        return 1;
      }
    }
    return 0;
  };
  function EncryptText(plainText, key) {
    const keyBytes = CryptoJS.enc.Base64.parse(key);
    const plainBytes = CryptoJS.enc.Utf8.parse(plainText);
    const encryptedBytes = CryptoJS.AES.encrypt(plainBytes, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedText = encryptedBytes.toString();
    return encryptedText;
  }
  const generateEncryptKey = (mode) => {
    //0 means button  and 1 means AUTo submit
    var ciphertext =
      mode === "BUTTON"
        ? EncryptText("0", process.env.REACT_APP_SECRET_KEY).toString()
        : EncryptText("1", process.env.REACT_APP_SECRET_KEY).toString();
    return ciphertext;
  };

  const confirmBets = async (clickedBy) => {

    let amount =
      curentInput * countStraightBet + curentRumbleInput * countRumbleBet ||
      totalBetAmount;
    if (amount > balance) {
      warningMsg("Bet amount exceeds current balance");
      return;
    }
    let submitMode = generateEncryptKey(clickedBy);

    let countStraight =
      straightData.filter((e) => e.length == 2)?.length * countStraightBet;
    let countRumble =
      rumbleData.filter((e) => e.length == 2)?.length * countRumbleBet;
    const value = {
      gameSessionId: gameSessionId,
      straightBetPayout: process.env.REACT_APP_MEGA2_STRAIGHT_PAYOUT_VALUE,
      rumbleBetPayout: process.env.REACT_APP_MEGA2_RUMBLE_PAYOUT_VALUE,
      playerId: playerId,
      submitMode: submitMode,
      rumbelBet: {
        betAmount: rumbleData.length > 0 ? countRumble : 0,
        numberOfBets: rumbleData.length,
        bets: countRumble == 0 ? [] : rumbleData.filter((e) => e.length == 2),
      },
      streghtBet: {
        betAmount: straightData.length > 0 ? countStraight : 0,
        numberOfBets: straightData.length,
        bets:
          countStraight == 0 ? [] : straightData.filter((e) => e.length == 2),
      },
      token: params.get("token"),
    };
    try {
      const response = await ConfirmPlaceBet(value);
      localStorage.setItem(
        "BetAmt",
        JSON.stringify({
          straightBet: countStraightBet,
          rumbleBet: countRumbleBet,
        })
      );
      if (response) {
        clickedBy === "AUTO_SUBMIT"
          ? setTimeout(
              () => submitResultForOperatorMega2Ball("AUTO BET PLACED"),
              4000
            )
          : submitResultForOperatorMega2Ball("Your Bet is placed");
        clickedBy === "BUTTON"
          ? setSubmitState("BUTTON")
          : setSubmitState("AUTO_SUBMIT");
        localStorage.setItem("submit", true);
        localStorage.setItem(
          "playerGameRoundId",
          response?.data?.data?.data?.id
        );

        setBalance(Number(response?.data?.data?.included?.member?.balance));
        setAllButtonDisable(true);
      } else {
        localStorage.setItem("submit", false);
        warningMsg("Error occur while placing bet");
      }
    } catch (error) {
      localStorage.setItem("submit", false);
      warningMsg("Error occur while placing bet");
    }
  };
  const handleButtonClick = () => {
    if (totalBetAmount > balance) {
      warningMsg("Bet amount exceeds current balance");
    } else {
      if (showMute === false) {
        playSoftButtonClickAudio();
      }
      //Validate here and then call the place bet api
      if (!validateGame()) {
        confirmBets("BUTTON"); //api for placing bet
      }
    }
  };
  //after select numbers..call api without click on comfirm button
  useEffect(() => {
    if (minutes === "01" && seconds === "00") {
      if (
        (straightData.length >= 1 && countStraightBet > 0) ||
        (rumbleData.length >= 1 && countRumbleBet > 0)
      ) {
        if (straightData[straightData?.length - 1]?.length < 2) {
          straightData?.splice(straightData?.length - 1, 1);
          setDisableRedBox(!disableRedBox);
        }
        if (rumbleData[rumbleData?.length - 1]?.length < 2) {
          rumbleData?.splice(rumbleData?.length - 1, 1);
          setDisableBlueBox(!disableBlueBox);
        }
        if (submitState === "") {
          if (countRumbleBet === 0) {
            setRumbleData([]);
          } else if (countStraightBet === 0) {
            setStraightData([]);
           
          } else if (straightData.length === 0) {
            setCountStraightBet(0);
          } else if (rumbleData.length === 0) {
            setCountRumbleBet(0);
          }
          let str_length = straightData.filter((e) => e.length == 2)?.length;
          let rum_length = rumbleData.filter((e) => e.length == 2)?.length;

          if (
            !(
              rum_length == 0 &&
              countStraightBet < 1 &&
              countRumbleBet < 1 &&
              str_length == 0
            )
          ) {
            confirmBets("AUTO_SUBMIT");
          }
        }
      }
    }
  }, [minutes, seconds]);

  const toggleHistory = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowHistory(!showHistory);
  };
  //History Box Handler

  const handleHistoryIconBox = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setGameHistoryOpen(true);
  };
  //switchGame from mega-2 Ball to Mega-3 Ball
  const handleSwitchGame = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setSwitchModalOpen(true);
  };

  let navigate = useNavigate();
  const clickYesToSwitch = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    navigate(`/player/Mega3Balls?token=${params.get("token")}`);
  };
  const clickNoToSwitch = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setSwitchModalOpen(false);
  };

  //Game Info & Rule handler
  const handleGameInfo = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setGameInfo(true);
  };
  const clickOkayInfoScreen = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setGameInfo(false);
  };

  //click Game Rules for Mega-2 Ball
  const clickGameRules = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowBox("gameRuleBox");
  };

  //click to see Payout
  const clickPayoutInfo = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowBox("payoutBox");
  };

  //Click too mute audio
  const clickAudioImage = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setShowMute(!showMute);
  };

  const ClickArrowDropUpChatBox = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setShowChatbox(!showChatbox);
  };

  //history Results Api - Showing the last 7 results on player page.
  const HistoryFunction = async () => {
    const response = await playerHistoryMega2Ball();
    // console.log({response},'HistoryFunction')
    setHistoryData(response?.history);
  };
  useEffect(() => {
    HistoryFunction();
  }, []);

  //Game-History  API Showing the same day result.
  const GameHistoryFunc = async () => {
    const resp = await GameHistoryMega2Ball();
    setTodayGameHistory(resp);
  };
  useEffect(() => {
    GameHistoryFunc();
  }, []);

  const totalBetAmount = useMemo(() => {
    let countStraight =
      straightData.filter((e) => e.length == 2)?.length * countStraightBet;
    let countRumble =
      rumbleData.filter((e) => e.length == 2)?.length * countRumbleBet;
    return countStraight + countRumble;
  }, [
    straightData,
    rumbleData,
    countRumbleBet,
    countStraightBet,
    straightData.filter((e) => e.length == 2)?.length,
    rumbleData.filter((e) => e.length == 2)?.length,
  ]);

  const ClickSendMsg = async () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    let x = messages.replace(/\s+/g, " ").trim();
    if (x !== "" && x !== " ") {
      const value = {
        playerName: playerName,
        message: messages,
        playerId: playerId,
        dateTime: new Date(),
      };
      const response = await ChatApiPlayer2(value);
      setMessages("");
    }
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [chats]);

  const player = useRef();

  useEffect(() => {
    player.current = TCPlayer("liveVideoPlayer", {
      sources: [
        {
          src: process.env.REACT_APP_MEGA2_LIVE_URL,
          // src: 'webrtc://global-lebtest-play.myqcloud.com/live/lebtest?txSecret=f22a813b284137ed10d3259a7b5c224b&txTime=69f1eb8c&tabr_bitrates=d1080p,d540p,d360p&tabr_start_bitrate=d1080p',
        },
      ],
      licenseUrl: process.env.REACT_APP_TCPLAYER_LICENSE_KEY, // License URL, see the preparation section. You can obtain the licenseUrl after applying for a license in the RT-Cube console
      language: "en", // Setting language en | zh-CN
    });

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);

  function isAddressBarVisible() {
    // Get the inner height (viewport height) and outer height (total window height)

    const innerHeight = window.innerHeight;

    const outerHeight = window.outerHeight;

    // The address bar is considered visible if the inner height is less than the outer height

    return innerHeight < outerHeight;
  }

  // Example usage
  useEffect(() => {
    if (isAddressBarVisible()) {
      setAddressBarVisible(true);
    } else {
      setAddressBarVisible(false);
    }

    window.addEventListener("touchstart", () => {
      if (isAddressBarVisible()) {
        setAddressBarVisible(true);
      } else {
        setAddressBarVisible(false);
      }
    });
  }, []);

  return (
    <Box
      marginX={"85"}
      className="bg-img-mega2-mob"
      style={{
        position: "relative",
        backgroundImage: `url(${bg})`,
        minHeight: "calc(100vh - 56px)",
        backgroundSize: "cover",
        overflowY: "auto",
      }}
      ref={content}
    >
      {/* <PlayerLiveStream videoSrc={'webrtc://chitu-pull.cdn03.com/live/Chitu1'} /> */}
      {showSwipeUp && (
        <div ref={swipeUpBoxRef} className={`displaySwipeUp ${showSwipeUp}`}>
          <img
            width={"100%"}
            height={"100%"}
            className="landscapeSwipeUP"
            src={landscapeSwipeUP}
          />
        </div>
       )}
      <BackdropRotate variant={"landscape"} />
      <InactiveUser playerStatus={playerStatus} />
      <WinnerModal
        congratsShowHide={congratsShowHide}
        totalBetAmount={winningAmount.current}
      />
      {/* Alert message popup, When operator is stop timer */}
      <LoserModal resultPopup={resultPopup} />
      <PausedModal
        timerStopPopup={timerStopPopup || resumedTimerPopup}
        msg={
          timerStopPopup === true
            ? "The game has been paused !"
            : "The game has been resumed !"
        }
        clickOkPaused={clickOkPaused}
      />
      {/* Alert message popup, When operator is end the gameSession */}
      <EndSessionModal
        endSessionPopup={endSessionPopup}
        showMute={showMute}
        setEndSessionPopup={setEndSessionPopup}
      />
      <ToastContainer />
      {/* /   Modal-Popup for switch Game in mega 2 ball  / */}
      {/* <SwitchGame
        SwitchModalOpen={SwitchModalOpen}
        setSwitchModalOpen={setSwitchModalOpen}
        showMute={showMute}
        switchTo={3}
        clickNoToSwitch={clickNoToSwitch}
        clickYesToSwitch={clickYesToSwitch}
      /> */}
      {/* /Game HistoryPopup-Model for Mega 2 ball / */}
      <HistoryModal
        gameHistoryOpen={gameHistoryOpen}
        betFrom={2}
        gameTitle={"Mega 2 ball"}
        showMute={showMute}
        historyType={historyType}
        setGameHistoryOpen={setGameHistoryOpen}
        setHistoryType={setHistoryType}
        todayGameHistory={todayGameHistory}
        GameHistoryComponent={SameDayGameHistory}
        PlayerHistoryComponent={PlayerGameHistoryIndex}
      />
      {/* Game information -Like rules and Payout- POPUP  */}
      <RulesModal
        gameInfo={gameInfo}
        setGameInfo={setGameInfo}
        clickGameRules={clickGameRules}
        clickPayoutInfo={clickPayoutInfo}
        showBox={showBox}
        okayButtonInfoPayout={okayButtonInfoPayout}
        clickOkayInfoScreen={clickOkayInfoScreen}
      />
      <Container maxWidth={false} className="custom-container">
        <Grid container>
          <Grid container justifyContent={"space-between"} className="navbar">
            <Box
              sx={{ maxWidth: { xs: "100px", md: "200px", lg: "300px" } }}
              className="logo-navbar landscapeNavbar"
            >
              <img
                src={imageLogo2}
                alt="images"
                className="logo-mega2Ball"
                style={{ maxWidth: "50%" }}
                sx={{ height: { xs: "34px", lg: "65px" } }}
              />
            </Box>
            {/* for tabs and mobile screens */}
            <WalletBalance
              sx={{
                display: { xs: "flex", lg: "none" },
                maxWidth: { xs: "100px", md: "100%" },
              }}
              Images={[creditPoint]}
              image2={Coin}
              text4={balance}
              image2css={"image2"}
              typo={"Name currencyText"}
              text3={"₱"}
              textCoin={"textCoin"}
              ActionButtonMega2Ball="ActionButtonMega2Ball payMaxW"
              className={"creditMega2Ball landscapeNavbar"}
            />
            <Box
              className="userAccount "
              sx={{ display: { xs: "flex", lg: "none" } }}
              title={playerName}
            >
              <img
                title={playerName}
                src={userProfileImage}
                alt={playerName}
                className="userProfileIcon landscapeNavbar"
              />
            </Box>
            {/* for collapsible menu in smaller devices */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              sx={{ display: { xs: "", lg: "none" } }}
              PaperProps={{
                className: "actionDropdown",
                elevation: 0,
                sx: {
                  backgroundColor: "#00232e",
                  border: "1px solid #2dc2f9",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 14,
                    right: -5,
                    width: 10,
                    height: 10,
                    bgcolor: "#00232e",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                    borderRight: "1px solid #2dc2f9",
                    borderTop: "1px solid #2dc2f9",
                  },
                },
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem onClick={() => handleClose("history")}>
                <img
                  src={historyImage}
                  alt="historyImage"
                  className="historyImage"
                />{" "}
                History{" "}
              </MenuItem>
              <MenuItem onClick={() => handleClose("Info")}>
                <img src={infoImage} alt="infoImage" className="infoImage" />
                Info
              </MenuItem>

              {/* //uncomment this and also for desktop devices */}
              {/* <MenuItem onClick={() => handleClose("Switch")}>
                {" "}
                <img
                  src={switchGame}
                  alt="switchGame"
                  className="switchGameIcon"
                />
                Switch{" "}
              </MenuItem> */}
              <MenuItem onClick={() => handleClose("Mute")}>
                {showMute === false ? (
                  <img
                    src={audioImage}
                    alt="audioImage"
                    className="audioImage"
                  />
                ) : (
                  <VolumeOffIcon
                    style={{
                      color: "rgb(45 194 249)",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                )}
                {showMute === false ? "Mute" : "Unmute"}
              </MenuItem>
            </Menu>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{
                  ml: 0,
                  display: { xs: "block", lg: "none" },
                  padding: { xs: "0px", sm: "5px" },
                }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVert
                  style={{ color: "#fff" }}
                  className="AccountLandscape"
                />
              </IconButton>
            </Tooltip>
            <Box
              className="switchGameBOx"
              sx={{ display: { xs: "none", lg: "flex" } }}
            >
              <WalletBalance
                sx={{ display: { xs: "flex", lg: "none" } }}
                Images={[creditPoint]}
                image2={Coin}
                text4={balance}
                textCoin={"textCoin"}
                image2css={"image2"}
                typo={"Name"}
                text3={"₱"}
                ActionButtonMega2Ball="ActionButtonMega2Ball"
                className={"creditMega2Ball"}
              />
              <Box
                onClick={() => {
                  handleHistoryIconBox();
                }}
                className="historyIconBoxMega2Ball"
              >
                <img
                  src={historyImage}
                  alt="historyImage"
                  className="historyImage"
                />
              </Box>
              <Box
                onClick={() => {
                  handleGameInfo();
                }}
                className="gameInfoBox"
              >
                <img src={infoImage} alt="infoImage" className="infoImage" />
              </Box>

              {/* //uncomment this and also for mobile devices */}
              {/* <Box
                onClick={() => {
                  handleSwitchGame();
                }}
                className="clickSwitchGame"
              >
                <img
                  src={switchGame}
                  alt="switchGame"
                  className="switchGameIcon"
                />
              </Box> */}
              <Box onClick={clickAudioImage} className="audioImageBox">
                <img src={audioImage} alt="audioImage" className="audioImage" />
                {showMute && (
                  <img
                    src={audioMuteImage}
                    alt="audioMuteImage"
                    className="audioMuteImage"
                  />
                )}
              </Box>
              <Box className="userAccount">
                <img
                  src={userProfileImage}
                  alt="userProfileImage"
                  className="userProfileImage"
                />
                {playerName}
              </Box>
            </Box>
          </Grid>
          <Grid
            container
            item
            columnSpacing={5}
            bgcolor={"#00000040"}
            marginBottom={"10px"}
            boxShadow={"gold"}
            paddingBottom="12px"
            borderRadius={"10px"}
            className="contentMinusNavbar"
          >
            <Grid
              item
              lg={4}
              sm={12}
              className={`${
                !showHistory ? "hide-history" : " "
              } liv-stream-div-mob`}
              style={{ height: showChatbox ? "0" : "auto", width: "100%" }}
            >
              <Box
                sx={{
                  "&.liveStreamingImage.test": {
                    display: { xs: "none", lg: showChatbox ? "none" : "block" },
                  },
                  display: { lg: showChatbox ? "none" : "block" },
                  height: showChatbox
                    ? "0"
                    : { xs: "100px", sm: "220px", md: "320px" },
                }}
                className="liveStreamingImage liveVideo"
                style={{
                  height: showChatbox
                    ? "0"
                    : { xs: "100px", sm: "220px", lg: "320px" },  margin:'10px 0px'
                }}
              >
                <video
                  className={"liveVideo"}
                  id="liveVideoPlayer"
                  controls="true"
                  autoPlay="true"
                  style={{
                    width: "100%",
                    borderRadius: "15px",
               
                  }}
                  onLoadedData={() => {
                    player.current.play();
                  }}
                  preload="auto"
                  playsinline="true"
                  webkit-playsinline="true"
                ></video>
              </Box>
              <Grid
                className="historyComponent"
                sx={{
                  display: { xs: "none", lg: showChatbox ? "none" : "block" },
                }}
              >
                <Box className="arrowBox">
                  <KeyboardArrowUpIcon
                    className="arrowOption"
                    onClick={toggleHistory}
                  />
                </Box>
                <Box>
                  <Box className="textHistory"> HISTORY</Box>
                </Box>
                <Box className={`history-box ${showHistory ? "show" : ""}`}>
                  <Box className="underlineHistory">
                    <img src={underlineHistory} alt="historyBar" />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    padding={"0px 12px 0px 15px"}
                    className="landscapeCounting"
                  >
                    <p className="counting">1st</p>
                    <p className="counting">2nd</p>
                    <p className="counting">3rd</p>
                    <p className="counting">4th</p>
                    <p className="counting">5th</p>
                    <p className="counting">6th</p>
                    <p className="counting">7th</p>
                  </Box>
                  <Box className="user-history-onScreenBox">
                    {[...new Array(7)].map((a, i) => (
                      <Box key={i}>
                        <Box className="userHistory-red-number">
                          {historyData?.[i]?.item1}
                        </Box>
                        <Box className="userHistory-blue-number">
                          {historyData?.[i]?.item2}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Box
                sx={{
                  "&.chatBox-playerPage": {
                    display: { xs: "none", md: "none", lg: "block" },
                  },
                  "&.chatBox-playerPage.test": { display: "block" },
                }}
                className="chatBox-playerPage "
                style={{
                  fontFamily: "Poppins",
                  height: showChatbox ? "calc(100vh - 163px)" : "",
                }}
              >
                <button className="livePlayer">Live</button>
                <Box
                  className="arrowDropUpChatBox"
                  onClick={() => {
                    ClickArrowDropUpChatBox();
                  }}
                  sx={{ display: { xs: "none", lg: "block" } }}
                >
                  <ArrowDropDownIcon />
                </Box>
                <Box className="chatboxMsg">
                  {chats?.length > 0 &&
                    chats?.map((chat, i) => (
                      <Box key={i} className="username1">
                        <Box className="username-details">
                          <p className="emojiTag">{chat?.playerName?.[0]}</p>
                          <p className="email">{chat?.playerName}</p>
                          <p className="sentnow">
                            {moment(chat?.dateTime).fromNow()}
                          </p>
                        </Box>
                        <p className="chatting">{chat?.message}</p>
                      </Box>
                    ))}
                  <Box ref={messagesEndRef} />
                </Box>
                <Grid display={"flex"}>
                  <Box className="typingBox">
                    <InputEmoji
                      placeholder="Type your message......."
                      className="enter-Box"
                      value={messages}
                      cleanOnEnter
                      onChange={setMessages}
                      onEnter={ClickSendMsg}
                    />
                    <p
                      className="sendIcon"
                      style={{
                        zIndex:
                          endSessionPopup ||
                          timerStopPopup ||
                          gameHistoryOpen ||
                          SwitchModalOpen ||
                          resultPopup ||
                          gameInfo
                            ? "0"
                            : "1",
                      }}
                      onClick={() => {
                        ClickSendMsg();
                      }}
                    >
                      <SendIcon />
                    </p>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              lg={8}
              sm={12}
              className={`mega2ball active-${activeGame}`}
              paddingRight={"0px"}
            >
              <Grid
                className="timer-Ball-Box redBlueBoxes"
                container
                marginBottom={"2rem"}
                spacing={4}
                sx={{ "& .MuiGrid-item": { paddingTop: "0px" } }}
              >
                <BetHeader
                  className="straightBet-handler remPding pdingRight"
                  subClass="playerStraightBet"
                  BetDisable={straightBetDisable}
                  rumbleImage={straightBetImages}
                  handleRumbleBet={handleStraightBet}
                  allButtonDisable={allButtonDisable}
                  title={"STRAIGHT BET"}
                  playSoftButtonClickAudio={playSoftButtonClickAudio}
                  showMute={showMute}
                  setCountRumbleBet={handleCountStraightBet}
                  countRumbleBet={countStraightBet}
                  rumblePayout={straightPayout}
                  balance={balance}
                  tempCountStraightBet={
                    (curentInput * countStraightBet +
                      curentRumbleInput * countRumbleBet || totalBetAmount) -
                    (curentRumbleInput * countRumbleBet || totalBetAmount) /
                      curentInput
                  }
                  amount={
                    curentInput * countStraightBet +
                      curentRumbleInput * countRumbleBet || totalBetAmount
                  }
                  rumblePayoutClass="straightPayout"
                  sx={{
                    order: { xs: "2", lg: "1" },
                    marginTop: { xs: 0, sm: 0 },
                  }}
                  PayoutKey={process.env.REACT_APP_MEGA2_STRAIGHT_PAYOUT_KEY}
                  PayoutCount={
                    process.env.REACT_APP_MEGA2_STRAIGHT_PAYOUT_VALUE
                  }
                  disableCount={disableStraightCount}
                />
                <Grid
                  item
                  order={1}
                  xs={12}
                  lg={6}
                  sx={{ position: "relative" }}
                  className="remPding"
                >
                  <IconButton
                    onClick={enableChat}
                    aria-label="delete"
                    size="small"
                    sx={{
                      display: { xs: "block", lg: "none" },
                      position: "absolute",
                      right: "0",
                      zIndex: {
                        xs:
                          endSessionPopup ||
                          timerStopPopup ||
                          gameHistoryOpen ||
                          SwitchModalOpen ||
                          resultPopup ||
                          gameInfo
                            ? "0"
                            : "1",
                      },
                    }}
                    className="iconBtn"
                  >
                    <img
                      className="enableChatLandscape"
                      src={chatImg}
                      alt="Chat Icon"
                    />
                  </IconButton>
                  <Grid container justifyContent={"center"}>
                    <Box
                      sx={{
                        order: { xs: "2", lg: "1" },
                        gap: { xs: "125px", sm: "30px" },
                        position: { xs: "absolute", sm: "inherit" },
                        top: { xs: "-10px", sm: "-40px" },
                      }}
                      display={"flex"}
                      className="RoundBall-mega2"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <RoundBall
                        Images={redball}
                        className={"ball-2"}
                        data1={roundRedBallData}
                        roundBallClass="roundball"
                      />
                      <RoundBall
                        Images={blueBall}
                        className={"ball-2"}
                        data1={roundBlueBallData}
                        roundBallClass="roundball"
                      />
                    </Box>
                    <Box
                      className="timerBoxMega2Ball"
                      sx={{
                        order: { xs: "1", lg: "2" },
                        height: { xs: "50px", md: "auto" },
                      }}
                    >
                      <Box className="timerShowBoxPlayerMega2">
                        {isRunning ? (
                          ""
                        ) : (
                          <p className="pausedTimer-show">Paused</p>
                        )}
                        <Box className="timingShowBox"> {minutes ?? ""} </Box>
                        <p className="dot-timer">:</p>
                        <Box className="timingShowBox"> {seconds ?? ""} </Box>
                      </Box>
                      <Box className="textTimerPlayerMega2">
                        <p>MINUTES</p>
                        <p>SECONDS</p>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <BetHeader
                  className="rumbleBet-handler remPding pdingLeft"
                  subClass="playerRumbelBet"
                  BetDisable={!straightBetDisable}
                  rumbleImage={rumbleBetImage}
                  handleRumbleBet={handleRumbleBet}
                  allButtonDisable={allButtonDisable}
                  title={"RUMBLE BET"}
                  playSoftButtonClickAudio={playSoftButtonClickAudio}
                  showMute={showMute}
                  setCountRumbleBet={handleCountRumbleBet}
                  countRumbleBet={countRumbleBet}
                  rumblePayout={rumblePayout}
                  rumblePayoutClass="rumblePayoutText"
                  sx={{ order: { xs: "3" }, marginTop: { xs: 0, sm: 0 } }}
                  PayoutKey={process.env.REACT_APP_MEGA2_RUMBLE_PAYOUT_KEY}
                  PayoutCount={process.env.REACT_APP_MEGA2_RUMBLE_PAYOUT_VALUE}
                  disableCount={disableRumbleCount}
                />
              </Grid>
              <Grid
                className="red-Blue-Inbox redBlueBoxes"
                container
                spacing={4}
                sx={{
                  "& .MuiGrid-item": {
                    paddingTop: "0px",
                    marginTop: { xs: "3px" },
                  },
                }}
              >
                <Grid xs={6} item className="remPding pdingRight">
                  <Box className="red-Blue-Inbox-first">
                    {[...new Array(15)].map((a, i) => (
                      <ShowBoxes
                        key={i}
                        index={i}
                        curentInput={curentInput}
                        BetDisable={straightBetDisable}
                        rumbleData={straightData}
                        roundRedBallData={roundRedBallData}
                        roundBlueBallData={roundBlueBallData}
                        setCurentInput={setCurentInput}
                        data={straightData}
                        setDisableRedBox={setDisableRedBox}
                        setDisableBlueBox={setDisableBlueBox}
                        straightBetClickStatus={straightBetClickStatus}
                        type={"STRAIGHT"}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid xs={6} item className="remPding pdingLeft">
                  <Box className="red-Blue-Inbox-second">
                    {[...new Array(15)].map((a, i) => (
                      <ShowBoxes
                        key={i}
                        index={i}
                        curentInput={curentRumbleInput}
                        BetDisable={straightBetDisable}
                        rumbleData={rumbleData}
                        roundRedBallData={roundRedBallData}
                        roundBlueBallData={roundBlueBallData}
                        setCurentInput={setCurentRumbleInput}
                        data={rumbleData}
                        setDisableRedBox={setDisableRedBox}
                        setDisableBlueBox={setDisableBlueBox}
                        straightBetClickStatus={straightBetClickStatus}
                        type={"RUMBLE"}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                className="red-And-BlueBox"
                sx={{
                  marginTop: { xs: "6px", lg: "1rem" },
                  "&.red-And-BlueBox.test": {
                    display: { xs: "none !important", sm: "flex !important" },
                  },
                  display: { sm: "flex" },
                  gap: { sx: 0, md: "10px" },
                }}
                style={{
                  opacity: allButtonDisable ? "0.5" : "",
                  pointerEvents: allButtonDisable ? "none" : "",
                }}
              >
                <Box position={"relative"}>
                  <PointBox
                    lineLeft={lineLeft}
                    lineRight={lineRight}
                    title="1st"
                    Images={redButton}
                    max={38}
                    className={"playerRedButton"}
                    fontName={"Discoteca Rounded"}
                    startIndex={1}
                    selectpoint={clickRedBox}
                    fontSize={25}
                    disabled={disableRedBox}
                    sx={{ gap: { xs: 0, sm: "3px" } }}
                  />
                </Box>
                <Box>
                  <PointBox
                    lineLeft={lineLeft}
                    lineRight={lineRight}
                    title="2nd"
                    Images={blueButton}
                    max={38}
                    className={"playerBlueButton"}
                    fontName={"Discoteca Rounded"}
                    startIndex={1}
                    selectpoint={clickBlueBox}
                    fontSize={25}
                    disabled={!disableRedBox}
                  />
                </Box>
              </Grid>
              <Grid
                className="confirmationButton"
                style={{
                  opacity: allButtonDisable ? "0.5" : "",
                  pointerEvents: allButtonDisable ? "none" : "",
                }}
              >
                <WalletBalance
                  Images={[creditPoint]}
                  image2={Coin}
                  text4={balance}
                  text3={"₱"}
                  image2css={"image2css"}
                  textCoin={"textCoin"}
                  typo={"typocss Name currencyContainer"}
                  className={"creditMega2Ball"}
                  ActionButtonMega2Ball="mega2Img payMaxW ActionButtonMega2Ball "
                  sx={{ display: { xs: "none", lg: "flex" } }}
                />

                <ActionButtonMega2Ball
                  Images={clearImage}
                  text="CLEAR"
                  className={"actionButton"}
                  playerMega2={clearData}
                  wholeData={straightBetDisable ? straightData : rumbleData}
                  mega2Img={"mega2Img"}
                />
                <ActionButtonMega2Ball
                  Images={luckyPickImage}
                  text={loading == "LUCKY_PICK" ? "Loading..." : "LUCKY PICK"}
                  curentInput={
                    activeGame === "straightBet"
                      ? curentInput
                      : curentRumbleInput
                  }
                  className={"luckyPickImage luckypick"}
                  playerMega2={luckyPick}
                  wholeData={!straightBetDisable ? straightData : rumbleData}
                  mega2Img={"mega2Img"}
                />
                <Box className="totalPaymentbtnBox">
                  <ActionButtonMega2Ball
                    Images={totalPayment}
                    text="total "
                    playerMega2={() => {}}
                    text2=" ₱ "
                    text3={totalBetAmount}
                    className={"totalPayoutBtn"}
                    style={{ width: "100%" }}
                    mega2Img={"mega2Img"}
                  />
                </Box>
                <ActionButtonMega2Ball
                  Images={confirmImage}
                  text="CONFIRM"
                  className={"submitBet actionButton "}
                  playerMega2={() => handleButtonClick()}
                  disabled={!buttonsActive}
                  mega2Img={"mega2Img"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default Index;
