import React, { useEffect, useState } from "react";
import { PlayerGameHistory } from ".";
import { Box, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  PlayerHistoryForMegaThreeBall,
  PlayerHistoryForMegaTwoBall,
} from "../../APIHandler/API";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
const PlayerGameHistoryIndex = ({ gameTitle, betFrom }) => {
  const [playerHistoryData, setPlayerHistoryData] = useState([]);
  const [params] = useSearchParams();
  const [searchDate, setSearchDate] = useState(dayjs(moment()));
  const [totalData,setTotalData]=useState(0)

  const playerHistoryFunc = async (data) => {
    const resp = await PlayerHistoryForMegaTwoBall(data);
    
    setPlayerHistoryData(resp?.histories);
    setTotalData(prev=>resp?.count)
  };
  const playerHistoryFuncForMega3 = async (data) => {
    const resp = await PlayerHistoryForMegaThreeBall(data);
    // setPlayerHistoryData(resp);
    setPlayerHistoryData(resp?.histories);
    setTotalData(prev=>resp?.count);
  };
   let getPlayerHistoryData=(data)=>{
      if (gameTitle == "Mega 2 ball") {
        //Get mega2 player history
        playerHistoryFunc(data);
      } else {
        playerHistoryFuncForMega3(data);
      }
   }
  useEffect(() => {
    let data = {
      token: params.get("token"),
      date: moment(searchDate).format("YYYY-MM-DD"),
      pageNumber: 1,
      pageSize: 20,
    };
   getPlayerHistoryData(data)
  }, []);

  const handleDateChange = (e) => {
    setSearchDate(dayjs(e));
    let data = {
        token: params.get("token"),
        date: moment(e.$d).format("YYYY-MM-DD"),
        pageNumber: 1,
        pageSize: 20,
      };
    getPlayerHistoryData(data)
  };

  return (
    <Box style={{width:"100%"}}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <h3 className="history_player_title"  sx={{fontSize:{ xs:"12px",md:"15px"}}}>Today's History</h3>
        <Box className="ballDateContainer">
          {/* <Typography variant="body2" className="ballTwo">{gameTitle}</Typography> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="historyInput"
              value={searchDate}
              slotProps={{ textField: { size: "small" } }}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <PlayerGameHistory
        playerHistoryData={playerHistoryData}
        betFrom={betFrom}
        searchDate={searchDate}
        setPlayerHistoryData={setPlayerHistoryData}
        gameTitle={gameTitle}
        setTotalData={setTotalData}
        totalData={totalData}
      />
    </Box>
  );
};

export default PlayerGameHistoryIndex;
