import axios from "axios";
import { warningMsg } from "../services/helpers";

//Confirm Bet for Player Mega 2 Ball
export async function ConfirmPlaceBet(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaTwoBall/PlaceBet`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}
export async function TimerStateMega3() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}TimerMegaThree/TimerState`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

//Reload data API for Player Mega-2 ball
export async function ReloadPagePlayerMega2Ball(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaTwoBall/PlaceBetReloadMagaTwoBall`,
      value,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

//Chat API for player page in mega-2 ball
export async function ChatApiPlayer2(value) {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}Chat/SendMessageMegaTwo`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return resp;
  } catch (errors) {
    warningMsg(errors?.response?.message);
    console.log(errors?.resp?.status, "error for chat api");
  }
}

//Payer History API for mega-2 ball in player page
export async function playerHistoryMega2Ball(value) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}GameSession/HistoryForMegaTwo`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response?.data;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

export async function GameHistoryMega2Ball(value) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}GameSession/HistoryForSameDayMegaTwo`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response?.data;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

//WinnerCalculation APi for Player-Mega2Ball
export async function WinnerCalculationPlayerMega2Ball(value) {
  try {
    const resposes = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaTwoBall/WinnerCalculation`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    console.log({ resposes });
    return resposes?.data;
  } catch (error) {
    warningMsg(error?.response?.message);
    console.log("API errors", error?.responses?.status);
  }
}

// Confirm Bet for Player Mega 3 Ball
export async function PlaceBetMega3(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaThreeBall/PlaceBet`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

//Player History API for mega-3 ball in player page
export async function ResultHistoryMega3Ball(value) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}GameSession/HistoryForMegaThree`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response?.data;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}
//Same Day History APIs for Mega-3 ball in player page...
export async function SameDayHistoryMega3(value) {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}GameSession/HistoryForSameDayMegaThree`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return resp?.data;
  } catch (error) {
    warningMsg(error?.response?.message);
    console.log("Error while Same day history Api", error?.resp?.status);
  }
}

//Reload data API for Player Mega-3 ball
export async function ReloadMega3BallPlayerPage(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaThreeBall/PlaceBetReloadMagaThreeBall`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

//WinnerCalculation APi for Player-Mega3Ball
export async function WinnerCalculationMega3Ball(value) {
  try {
    const resposes = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaThreeBall/WinnerCalculation`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return resposes.data;
  } catch (error) {
    warningMsg(error?.response?.message);
    console.log("API errors", error?.responses?.status);
  }
}

//Chat API for player page in mega-# ball
export async function ChatApiMega3(value) {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}Chat/SendMessageMegaThree`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return resp;
  } catch (errors) {
    warningMsg(errors?.response?.message);
    console.log(errors?.resp?.status, "error for chat api");
  }
}

export async function validateUserSessionID(payload) {
  try {
    // `https://breddasinc-gamc-uat-api.chetu.com/api/Game/getMemberDetails`
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}Game/getMemberDetails`,
      payload,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response;
  } catch (err) {
    warningMsg(err?.response?.data?.error?.message);
    // console.log("API error", err?.response?.status,err?.response?.data?.error?.message);
  }
}

export async function PlayerHistoryForMegaTwoBall(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaTwoBall/PlayerHistory`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response?.data;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

export async function PlayerHistoryForMegaThreeBall(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaThreeBall/PlayerHistory`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    return response?.data;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}

export async function cancelRound(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}GameSession/CancelRound `,
      data,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    console.log("cancel round ", data, response);
    return response?.data;
  } catch (err) {
    // warningMsg(err?.response?.message);
    if (err?.response?.status == 200) {
      warningMsg("Error occured while round cancelled");
    }
    console.log("API error", err?.response?.status);
  }
}

export async function cancelRoundMega3() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}TimerMegaThree/CancelRound `,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY
        }
      }
    );
    console.log("cancel round mega 3", response);
    return response?.data;
  } catch (err) {
    warningMsg(err?.response?.message);
    console.log("API error", err?.response?.status);
  }
}
